import React, { useState, useEffect, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomToastContainer from "../Common/CustomToastContainer";

import { getUserData } from "../../helpers/utils";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { usePopup } from "../Common/PopupContext";
import EditUser from "../popup/Profilepagepopup/EditUser.js";
import { useParams, useHistory } from "react-router-dom";
import api from "../../Api";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const getTextColor = (percent) => {
  if (percent > 80) return "green";
  if (percent > 60) return "orange";
  return "red";
};

const Profile = () => {
  let paramdata = useParams();
  let percentage = "0";
  const User = getUserData();
  const [Profile, setProfile] = useState();
  const [error, setError] = useState("");
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);
  // const [popupComponent, setPopupComponent] = useState(null);
  const [jobAnalysis, setjobAnalysis] = useState({
    recommendation_percent: 0,
    gaps: {
      experience: "",
      education: [],
      skills: [],
    },
    matching_skills: [],
    unmatched_skills: [],
  });
  const history = useHistory();
  const toastRef = useRef();
  // const skills = ["Photo Shop", "Illustrator", "Corel Draw", "Banner"];
  useEffect(() => {
    if (paramdata.profileid !== undefined) {
      setLoading(true);

      async function fetchData() {
        try {
          // Fetch profile ID
          const profileResponse = await api.getProfileId(paramdata.profileid);
          //console.log(profileResponse);

          if (profileResponse.status !== 500) {
            setProfile(profileResponse);
          } else if (profileResponse.status === 500) {
            // Handle 500 error for profile response
          }

          // Fetch job profile analysis
          const analysisResponse = await api.getJobProfileAnalysis(
            paramdata.jobid,
            paramdata.profileid
          );
          if (analysisResponse.success === true) {
            setjobAnalysis(analysisResponse.jsonObject);
          } else if (analysisResponse.status === 500) {
            // Handle 500 error for analysis response
          }

          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }

      fetchData();
    }

    setSkills(["Photo Shop", "Illustrator", "Corel Draw", "Banner"]);
  }, [paramdata.profileid, paramdata.jobid]);
  const handleClosePopup = (message) => {
    //setPopupComponent(null);
    openPopup();
  };
  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        domainpath + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfile((prevData) => ({
        ...prevData,
        jobProfileImage: response.data.imagePath,
      }));
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };

  const handlEditUser = (values) => {
    const popup = (
      <EditUser
        onSave={(formData) => handleProfileform(formData)}
        onClose={() => handleClosePopup("Popup closed")}
        initialValues={values}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup); // Added this line
  };
  const handleProfileform = (formData) => {
    setProfile(formData);
  };

  const textColor = getTextColor(jobAnalysis?.recommendation_percent);
  const handleJobApply = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = {
        jobid: paramdata?.jobid,
        profileid: paramdata?.profileid,
        applicantid: User?._id,
        jsonObject: jobAnalysis,
      };
      const res = await api.applyjob(formData);

      if (res.status == 200) {
        toastRef.current.addToast(res.message, "success");
        setLoading(false);
        setTimeout(() => {
          history.push("/jobs", { jobAnalysis });
        }, 2000);
      }
    } catch (err) {
      toastRef.current.addToast(
        err.response ? err.response.data : err.message,
        "error"
      );
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
    // Optionally, clear the form or redirect the user
  };

  const handleImproveSkills = () => {
    // Get the matching skills from jobAnalysis
    const skills = jobAnalysis?.matching_skills || [];

    // Get the job industry
    const industry = Profile?.industry || ""; // Replace with correct path to industry if different

    // Convert skills array to query string
    const skillsQuery = skills
      .map((skill) => `skills=${encodeURIComponent(skill)}`)
      .join("&");

    // Create the full query string including industry
    const queryParams = `industry=${encodeURIComponent(
      industry
    )}&${skillsQuery}`;

    // Navigate to the courses page with the query parameters
    history.push(`/courses?${queryParams}`);
  };
  return (
    <div className="container mx-auto p-4 bg-white shadow-lg">
      <div className="profile-header md:flex border-b pb-4 mb-4 items-start">
        <div className="relative lg:w-52  hrp-profile-header-image">
          <CircularProgressbar
            value={jobAnalysis?.recommendation_percent}
            styles={buildStyles({
              rotation: 0.5,
              strokeLinecap: "butt",
              textSize: "16px",
              pathTransitionDuration: 0.5,
              pathColor: `rgba(0, 128, 1, ${
                jobAnalysis?.recommendation_percent / 100
              })`,
              textColor: "#f88",
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
              strokeWidth: 3,
            })}
          />
          <label
            htmlFor="companyLogo"
            className="absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
          >
            <input
              type="file"
              id="companyLogo"
              accept="image/*"
              name="companyLogo"
              onChange={(e) => handleUpload(e.target.files[0])}
              className="hidden"
            />
            <div className="w-full bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image ">
              <img
                src={
                  Profile?.jobProfileImage &&
                  Profile?.jobProfileImage.trim() !== "" &&
                  Profile?.jobProfileImage !== "NA"
                    ? `${domainpath}${Profile?.jobProfileImage}`
                    : domainpath + "/uploads/deafultman.png"
                }
                alt="Profile"
                className="w-full h-full rounded-full"
              />
              <div
                className="absolute lg:top-10 md:top-4 right-0 transform -translate-x-1/2 translate-y-1/2 bg-blue-500 p-1 rounded-full"
                style={{ right: "-30px" }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-white md:w-4 md:h-4"
                >
                  <path
                    d="M9 3H15L17 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H7L9 3ZM12 19C13.5913 19 15.1174 18.3679 16.2426 17.2426C17.3679 16.1174 18 14.5913 18 13C18 11.4087 17.3679 9.88258 16.2426 8.75736C15.1174 7.63214 13.5913 7 12 7C10.4087 7 8.88258 7.63214 7.75736 8.75736C6.63214 9.88258 6 11.4087 6 13C6 14.5913 6.63214 16.1174 7.75736 17.2426C8.88258 18.3679 10.4087 19 12 19ZM12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13C8 11.9391 8.42143 10.9217 9.17157 10.1716C9.92172 9.42143 10.9391 9 12 9C13.0609 9 14.0783 9.42143 14.8284 10.1716C15.5786 10.9217 16 11.9391 16 13C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </label>
          <div className="absolute lg:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center hrp-profile-section-text">
            <div className="text-center text-gray-700 font-medium text-lg">
              {jobAnalysis?.recommendation_percent}%
            </div>
          </div>
        </div>
        <div className="profile-info ml-4 xl:flex items-center ">
          <div className="profile-info xl:ml-4 flex-1 mainprofile">
            <h1 className="text-lg font-medium flex items-center">
              {/* Mr. John{" "} */}
              {Profile?.salutation}{" "}
              {Profile?.firstName + " " + " " + Profile?.lastName}
              <span
                className="ml-2 text-lg cursor-pointer"
                onClick={() => handlEditUser(Profile)}
              >
                &#9998;
              </span>
            </h1>
            {Profile?.jobTitle != "" && Profile?.jobTitle != null ? (
              <p className="text-gray-600 font-medium">{Profile?.jobTitle}</p>
            ) : (
              ""
            )}
            {Profile?.employment?.[0]?.company != "" &&
            Profile?.employment?.[0]?.company != null ? (
              <p className="text-gray-600">
                at {Profile?.employment?.[0]?.company}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="details flex flex-wrap mt-2">
            {Profile?.location != "" && Profile?.location != null ? (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#x1F4CD;</span> {Profile?.location}
              </div>
            ) : (
              ""
            )}
            {Profile?.experience != "" && Profile?.experience != null ? (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#128197;</span>
                {Profile?.experience}
              </div>
            ) : (
              ""
            )}
            {Profile?.currentCTC != "" && Profile?.currentCTC != null ? (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#x1F4B0;</span>
                {Profile?.currentCTC}
              </div>
            ) : (
              ""
            )}
            {Profile?.phone != "" && Profile?.phone != null ? (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#128222;</span> {Profile?.phone}
                <span className="ml-2 text-green-600">&#10004;</span>
              </div>
            ) : (
              ""
            )}
            {Profile?.email != "" && Profile?.email != null ? (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#x2709;</span> {Profile?.email}
                <span className="ml-2 text-green-600">&#10004;</span>
              </div>
            ) : (
              ""
            )}
            {Profile?.noticePeriod && (
              <div className="detail-item md:w-1/2 flex items-center mb-2">
                <span className="mr-2">&#128197;</span> {Profile?.noticePeriod}
              </div>
            )}
          </div>
          {/* <button className="joblist-btnclear change-profile-btn bg-blue-500 text-white py-2 px-4 mt-4 rounded hover:bg-blue-700">
                      Change Profile
                    </button> */}
        </div>
      </div>
      <div className="job-applied mb-4 locationprofile">
        <div className="locationprofile-inner ">
          <h2 className="text-xl  pb-2 mb-2">Job Applications</h2>
          <h1 className="text-lg font-medium">Job Match</h1>
        </div>

        <div className="job-match flex px-10 py-10 justify-between items-center">
          <div className="job-item mb-2">
            {Profile?.jobTitle != "" && Profile?.jobTitle != null ? (
              <h1 className="text-lg">{Profile?.jobTitle}</h1>
            ) : (
              ""
            )}
            {Profile?.location != "" && Profile?.location != null ? (
              <h2 className="text-gray-600">
                <FeatherIcon icon="map-pin" />
                {Profile?.location}
              </h2>
            ) : (
              ""
            )}
            {Profile?.currentCTC != "" && Profile?.currentCTC != null ? (
              <h3 className="text-gray-600">
                {" "}
                <span className="mr-2">&#x1F4B0;</span>
                {Profile?.currentCTC}
              </h3>
            ) : (
              ""
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            {Profile?.recommendation_percent != "" ||
            Profile?.recommendation_percent != null ||
            Profile?.recommendation_percent ? (
              <h2
                className="match-percentage text-3xl"
                style={{ color: textColor }}
              >
                {jobAnalysis?.recommendation_percent}%
              </h2>
            ) : (
              ""
            )}
            <div className="">
              <button
                className="hrp-find-course-btn rounded-full lg:py-2 joblist-mobile-views lg:w-full  md:py-3 md:px-3 px-9 py-2"
                onClick={(e) => handleJobApply(e)}
              >
                Apply for this position
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 overflow-x-auto job-applied mb-4 locationprofile">
        <div className="locationprofile-inner">
          <h2 className="text-xl font-semibold pb-2 mb-2">Summary</h2>
        </div>
        <table className="min-w-full divide-y divide-gray-200 table-auto">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                Category
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-3/4">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Experience
              </td>
              <td className="px-4 py-4 text-sm text-gray-500">
                {jobAnalysis?.gaps.experience || "No data available"}
              </td>
            </tr>
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Education
              </td>
              <td className="px-4 py-4 text-sm text-gray-500">
                <ul className="list-disc pl-5">
                  {jobAnalysis?.gaps?.education?.length > 0 ? (
                    jobAnalysis?.gaps.education.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No educational gaps</li>
                  )}
                </ul>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Skills
              </td>
              <td className="px-4 py-4 text-sm text-gray-500">
                <ul className="list-disc pl-5">
                  {jobAnalysis?.gaps?.skills?.length > 0 ? (
                    jobAnalysis?.gaps.skills.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  ) : (
                    <li>No skill gaps</li>
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex space-x-8 p-6 bg-gray-100">
        {/* Skill Match */}
        <div className="bg-white rounded-lg shadow-md p-4 w-1/2">
          <h2 className="text-lg font-semibold mb-4">Skill match</h2>
          <div className="flex space-x-2  space-y-2 flex-wrap ">
            {jobAnalysis?.matching_skills.map((skill, index) => (
              <div
                key={index}
                className="text-yellow-600 rounded-full px-4 py-1 flex items-center space-x-2 skillmatch"
              >
                <span>{skill}</span>
                <svg
                  className="skillcross"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="10" fill="white" />
                  <path
                    d="M12 1.5C6.15 1.5 1.5 6.15 1.5 12C1.5 17.85 6.15 22.5 12 22.5C17.85 22.5 22.5 17.85 22.5 12C22.5 6.15 17.85 1.5 12 1.5ZM12 21C7.05 21 3 16.95 3 12C3 7.05 7.05 3 12 3C16.95 3 21 7.05 21 12C21 16.95 16.95 21 12 21Z"
                    fill="#FFA500"
                  />
                  <path
                    d="M16.05 17.25L12 13.2L7.95 17.25L6.75 16.05L10.8 12L6.75 7.95L7.95 6.75L12 10.8L16.05 6.75L17.25 7.95L13.2 12L17.25 16.05L16.05 17.25Z"
                    fill="#FFA500"
                  />
                </svg>
              </div>
            ))}
          </div>
        </div>

        {/* Skill Gap */}
        <div className="bg-white rounded-lg shadow-md p-4 w-1/2">
          <h2 className="text-lg font-semibold mb-4">Skill Gap</h2>
          <div className="flex space-x-2 flex-wrap">
            {jobAnalysis?.unmatched_skills.map((skill, index) => (
              <div
                key={index}
                style={{ margin: "2px" }}
                className="bg-white border border-blue-600 text-blue-600 rounded-full px-4 py-1"
              >
                {skill}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" mt-5 border rounded">
        <div className="Next-Step">
          <div>Next Step</div>
        </div>
        <div className="ApplyanyJobsfirst-main">
          <div className="ApplyanyJobsfirst">
            <button onClick={(e) => handleJobApply(e)}>Apply any way</button>
          </div>
          <div className="ApplyanyJobsfirst">
            <button onClick={handleImproveSkills}>Improve Skills</button>
          </div>
          <div className="ApplyanyJobsfirst">
            <button onClick={(e) => history.push("/jobs", { jobAnalysis })}>
              {" "}
              Find Jobs Matches to Skills
            </button>
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Profile;
