// AdminLayout.js
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import "../../../src/css/responcive.css";
import { CVDownloadProvider } from "../../helpers/CVDownloadContext";
import { Header, EmployerHeader, CandidateHeader } from "./Header";
import Signup from "../../components/Website/Signup.js";
import SellerSignup from "../../components/Website/SellerSignup.js";
import Registepage from "../../components/Website/Registerform.js";
import TermAndConditions from "../../components/Website/TermAndConditions.js";
import PrivacyPolicy from "../../components/Website/PrivacyPolicy.js";
import AntiSlavery from "../../components/Website/AntiSlaveryPolicy.js";
import Employers from "../../components/Website/Employers.js";
import Candidates from "../../components/Website/Candidates.js";
import Loginform from "../../components/Website/Signup.js";
import UploadCV from "../../components/Website/UploadCV.js";
import ForgotPassword from "../../components/Website/ForgotPassword.js";
import Home from "../../components/Website/home.js";
import Home2 from "../../components/Website/home2.js";
import ResetPassword from "../../components/Website/ResetPassword.js";
import Jobs from "../../components/Website/jobs.js";
import postJob from "../../components/Website/postJob.js";
import { PopupProvider } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
import SingleJob from "../Website/SingleJobpage.js";
import Course from "../Website/Course.js";
import Coursesingle from "../Website/Course-single.js";
import Userprofile from "../Website/Userprofile.js";
import NotFound from "../Website/NotFound.js";
import SingleBlog from "../../components/Website/singleblog.js";
import Blogs from "../../components/Website/blogs.js";
import VerifyEmail from "../../components/Website/Verification.js";

function Websitelayout() {
  const useLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue;
  };

  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);
  const User = getUserData(); // Get user data from localStorage
  const role = User ? User.role : null; // Get the base path for nested routes
  const renderComponentBasedOnRole = (role) => {
    switch (role) {
      case "superadmin":
        return <EmployerHeader />;
      case "applicant":
        return <CandidateHeader />;
      case "employer":
        return <EmployerHeader />;
      default:
        return <Header />; // Default to general header for non-logged-in users or unknown roles
    }
  };

  return (
    <CVDownloadProvider>
      <PopupProvider>
        {renderComponentBasedOnRole(role)}

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home-2" component={Home2} />
          <Route path="/signup" component={Signup} />
          <Route path="/seller-signup" component={SellerSignup} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/login" component={Loginform} />
          <Route path="/register" component={Registepage} />
          <Route path="/terms-conditions" component={TermAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/anti-slavery" component={AntiSlavery} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/blogs/:slug" component={SingleBlog} />
          <Route path="/verify-email/:token" component={VerifyEmail} />
          <Route path="/employers" component={Employers} />
          <Route path="/candidates" component={Candidates} />
          <Route path="/candidate/:profileid" component={Candidates} />
          <Route exact path="/jobs" component={Jobs} />
          <Route path="/jobs/:jobid" component={SingleJob} />
          <Route path="/jobs/:jobid/:profileid" component={SingleJob} />
          <Route path="/post-job" component={postJob} />
          <Route path="/post-job/:id" component={postJob} />
          <Route path="/upload-cv" component={UploadCV} />
          <Route exact path="/courses" component={Course} />
          <Route path="/courses/:courseid" component={Coursesingle} />
          {/* not linked */}
          <Route path="/profile/:profileid" component={Userprofile} />
          <Route path="*" component={NotFound} />
        </Switch>

        <Footer />
      </PopupProvider>
    </CVDownloadProvider>
  );
}

export default Websitelayout;
