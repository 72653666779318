import React, { useEffect, useRef, useState } from "react";
import Select2 from "../Common/Select2Wrapper";
import api from "../../Api";
import { useParams } from "react-router-dom";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";

const TicketForm = () => {
  const User = getUserData();
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  const [formData, setFormData] = useState({
    jobId: "",
    subject: "",
    description: "",
    status: "",
  });
  const jobidRef = useRef();
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "description") {
      setFormData({ ...formData, description: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSelectChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  useEffect(() => {
    async function fetTicket() {
      setLoading(true);
      try {
        const res = await api.getTicketbyId(id);
        if (res.status == 200) {
          const data = res?.ticket;
          console.log(data);

          setFormData({
            jobId: data.jobId._id,
            subject: data.subject,
            description: data.description,
            status: data.status || "Open",
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching blog data:", err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetTicket();
    async function getJobs() {
      try {
        const response = await api.getJobsByuserId(User._id);
        if (response.status !== 400) {
          setdata(
            response.map((job) => ({
              id: job._id,
              text: job.title,
            }))
          );
        } else if (response.status === 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getJobs();
  }, [id]);

  const dummyJobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    //console.log(formData);
    try {
      const response = await api.editTicket(id, formData);
      if (response.status == 200) {
        toastRef.current.addToast("Ticket Updated Successffully", "success");
        setLoading(false);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="hrp-ticket-create">
      <div className="w-3/4 m-auto p-10 ">
        <form
          onSubmit={handleSubmit}
          className="animated-form profileheadlinediv space-y-6"
        >
          <div className="flex justify-between items-center">
            <div
              className="py-5 text-2xl font-bold"
              style={{ color: "#003366" }}
            >
              Edit Ticket
            </div>
            <div className="w-36">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="statusOptions"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="statusOptions"
                  multiple={false}
                  defaultValue={formData.status}
                  onChange={(e) => handleSelectChange(e, "status")}
                  data={dummyJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="statusOptions"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Status
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="relative job-search-label-section singleselect2">
              <Select2
                id="jobId"
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                name="jobId"
                ref={jobidRef}
                multiple={false}
                value={formData.jobId}
                onChange={(e) => handleSelectChange(e, "jobId")}
                data={data.map((job) => ({
                  id: job.id,
                  text: job.text,
                }))}
                options={{
                  placeholder: "Select a job",
                  theme: "classic",
                  width: "resolve",
                }}
              />
              <label
                htmlFor="jobId"
                className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
              >
                Job ID
              </label>
            </div>

            <div className="form-group">
              <input
                id="subject"
                placeholder=""
                value={formData.subject}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
                rows="4"
              />
              <label htmlFor="subject" className="profileheadline">
                Subject
              </label>
            </div>
          </div>
          <div className="form-group">
            <textarea
              id="description"
              placeholder=""
              value={formData.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700"
              rows="4"
            />
            <label htmlFor="Query" className="profileheadline">
              Query
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button type="submit" className="Job-Post-btn text-white">
              Save
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default TicketForm;
