import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
const domainpath = process.env.REACT_APP_DOMAIN;

const ManageJobs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const history = useHistory();
  const toastRef = useRef();

  const handleDelete = async (jobId) => {
    const response = await api.deletejob(`${jobId}`);
    //console.log(response);

    if (response.status === "200") {
      toastRef.current.addToast(response.message, "success");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the job!",
        "error"
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        if (showMessages) {
          const response = await api.getJobsByAdmin();
          if (response.status === 200) {
            setData(response?.jobsWithApplicants);
          }
        } else if (showNotes) {
          const response = await api.getJobsByReported();
          if (response.status === 200) {
            const filteredJobs = response?.reportedJobs?.filter(
              (job) => job.jobId !== null && job.jobId !== undefined
            );
            setData(filteredJobs);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [showMessages, showNotes]);
  const status = [
    { value: "Publish", label: "Published" },
    { value: "Hold", label: "Hold" },
    { value: "Disabled", label: "Disabled" },
    { value: "Closed", label: "Closed" },
    { value: "Draft", label: "Draft" },
    { value: "Block", label: "Block" },
  ];
  const handleUpdateJObStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.UpdateJObStatus(id, formdata);
      toastRef.current.addToast(res.message, "success");
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  // Dynamic Columns Management
  const columns = showMessages
    ? [
        // Columns for "Manage Jobs"
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row?._id}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
              >
                {row?.title || ""}
              </a>
              <br />
              {row.candidateId
                ? `${row.candidateId?.name} (${row.candidateId?.email})`
                : "Candidate details not available"}
              <br></br>
              {row?.location?.address !== ""
                ? row?.location?.address
                : row?.location?.city +
                  " " +
                  row?.location?.area +
                  row?.location?.pincode}
            </>
          ),
          width: "25%",
          sortable: true,
        },

        {
          name: "Status",
          selector: (row) => (
            <div
              className="relative job-search-label-section singleselect2 statusenroll"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Select2
                data={status.map((type) => ({
                  id: type.value,
                  text: type.label,
                }))}
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                value={row.status}
                onSelect={(e) => handleUpdateJObStatus(e, row._id)}
                options={{
                  placeholder: "Select Status",
                  width: "100%",
                  theme: "classic",
                }}
              />
            </div>
          ),
          sortable: true,
          width: "20%",
        },
        {
          name: "Applicant/Shortlisted",
          selector: (row) => (
            <a
              href={`/dashboard/job/${row._id}`}
              className="hover:underline"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row?.totalApplicants + "/" + row?.shortlistedApplicants}
            </a>
          ),
          sortable: true,
          width: "20%",
        },
        {
          name: "Posted On",
          selector: (row) => new Date(row.postedDate).toLocaleDateString(),
          sortable: true,
          width: "10%",
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="flex gap-2 w-full item-center justify-center">
              <button
                className="manage-edit-btn text-white px-6 py-2"
                onClick={() => history.push(`./jobpost/${row._id}`)}
              >
                <FeatherIcon icon="edit-2" />
              </button>
              <button
                className="manage-delete-btn text-white px-6 py-2"
                onClick={() => handleDelete(row._id)}
              >
                <FeatherIcon icon="trash" />
              </button>
            </div>
          ),
          width: "25%",
        },
      ]
    : [
        // Columns for "Reported Jobs"
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row.jobId?._id}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
              >
                {row.jobId?.title}
              </a>
              <br />
              {row.candidateId?.name} ({row.candidateId?.email})
            </>
          ),
          width: "25%",
          sortable: true,
        },
        {
          name: "Reasons",
          selector: (row) =>
            Array.isArray(row.reasons)
              ? row.reasons.join(", ")
              : "No reasons provided",
          sortable: true,
          width: "24%",
        },
        {
          name: "Details",
          selector: (row) => row.details,
          sortable: true,
          width: "25%",
        },
        {
          name: "Reported Date",
          selector: (row) => new Date(row.createdAt).toLocaleDateString(),
          sortable: true,
          width: "10%",
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="flex gap-1">
              <button
                className="manage-edit-btn text-white px-6 py-2"
                onClick={() => history.push(`./jobpost/${row.jobId?._id}`)}
              >
                <FeatherIcon icon="edit-2" />
              </button>
              <button
                className="manage-delete-btn text-white px-6 py-2"
                onClick={() => handleDelete(row.jobId?._id)}
              >
                <FeatherIcon icon="trash" />
              </button>
            </div>
          ),
          width: "15%",
        },
      ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8 hrp-manage-job">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex gap-2 mb-4">
          <button
            className={`mt-2 px-4 py-2 hrp-ticket-btn ${
              showMessages ? "activebtn" : "inactive"
            } text-white`}
            onClick={() => {
              setShowMessages(true);
              setShowNotes(false);
            }}
          >
            Manage Jobs
          </button>
          <button
            className={`mt-2 px-4 py-2 hrp-ticket-btn ${
              showNotes ? "activebtn" : "inactive"
            } text-white`}
            onClick={() => {
              setShowMessages(false);
              setShowNotes(true);
            }}
          >
            Reported Jobs
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        )}
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageJobs;
