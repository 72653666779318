import axios from "axios";
import Cookies from "js-cookie";

// const BASE_URL_PLATFORM = "https://api.hirepros.co.uk";
// const BASE_URL_EXCHANGE = "https://api.hirepros.co.uk";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const BASE_URL_EXCHANGE = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const BASE_DOMAIN_URL = "https://hirepros.co.uk";
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

const getBaseUrl = (url) => {
  return url.startsWith("/exchange") ? BASE_URL_EXCHANGE : BASE_URL_PLATFORM;
};

export const refreshTokens = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${BASE_URL_PLATFORM}/api/auth/refresh-token`,
      {
        refreshToken: refreshToken,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const request = async (
//   method,
//   url,
//   data = null,
//   requiresAuth = true
// ) => {
//   const BASE_URL = getBaseUrl(url);

//   try {
//     let headers = {};
//     if (requiresAuth) {
//       const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
//       if (!accessToken) {
//         throw new Error("Access token not found");
//       }
//       headers = {
//         Authorization: `${accessToken}`,
//       };
//     }

//     const response = await axios({
//       method,
//       url: `${BASE_URL}${url}`,
//       headers,
//       data,
//     });
//     return response.data;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
//       if (!refreshToken) {
//         throw new Error("Refresh token not found");
//       }

//       try {
//         const refreshedTokens = await refreshTokens(refreshToken);
//         localStorage.setItem(ACCESS_TOKEN_KEY, refreshedTokens.accessToken);
//         localStorage.setItem(REFRESH_TOKEN_KEY, refreshedTokens.refreshToken);

//         return request(method, url, data, requiresAuth);
//       } catch (refreshError) {
//         throw refreshError;
//       }
//     }
//     throw error;
//   }
// };

export const request = async (
  method,
  url,
  data = null,
  requiresAuth = true
) => {
  const BASE_URL = getBaseUrl(url);

  try {
    let headers = {};
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    // Always include the Authorization header if the token is available
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const response = await axios({
      method,
      url: `${BASE_URL}${url}`,
      headers,
      data,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 403) {
      clearAuthTokens();
      console.log("Access forbidden, tokens cleared. Redirect to login.");
      // Optionally, redirect to login page or show a relevant message
      window.location.href = "/"; // Ensure this is where your login page is located
    }
    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      try {
        const refreshedTokens = await refreshTokens(refreshToken);
        localStorage.setItem(ACCESS_TOKEN_KEY, refreshedTokens.accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshedTokens.refreshToken);

        return request(method, url, data, requiresAuth);
      } catch (refreshError) {
        throw refreshError;
      }
    }
    throw error;
  }
};

const clearAuthTokens = () => {
  const adminAccessToken = Cookies.get("adminaccessToken");
  const adminRefreshToken = Cookies.get("adminrefreshToken");
  const adminUser = Cookies.get("adminuser");

  if (adminAccessToken && adminRefreshToken && adminUser) {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("user");
    Cookies.set("accessToken", adminAccessToken);
    Cookies.set("refreshToken", adminRefreshToken);
    Cookies.set("user", adminUser);
    Cookies.remove("adminaccessToken");
    Cookies.remove("adminrefreshToken");
    Cookies.remove("adminuser");
    localStorage.clear();
    sessionStorage.clear();
    // Delay page reload to let the toast show
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  } else {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("user");
    localStorage.clear();
    sessionStorage.clear();

    // Delay page reload to let the toast show
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  // You can add more cookies if necessary, just ensure they're set to expire in the past
};

export const get = async (url, requiresAuth = true) => {
  return request("get", url, null, requiresAuth);
};

export const post = async (url, data, requiresAuth = true) => {
  return request("post", url, data, requiresAuth);
};

export const put = async (url, data, requiresAuth = true) => {
  return request("put", url, data, requiresAuth);
};

export const del = async (url, requiresAuth = true) => {
  return request("delete", url, null, requiresAuth);
};

const api = {
  getUsers: async () => {
    return get("/api/users/superadmin/all", false);
  },
  signup: async (userData) => {
    return post("/api/auth/signup", userData, false);
  },
  employerSignup: async (userData) => {
    return post("/api/users/employer-signup", userData, false);
  },
  applicantSignup: async (userData) => {
    return post("/api/users/applicant-signup", userData, false);
  },
  UpdateUserStatus: async (id, status) => {
    return put(`/api/users/${id}/update`, status, false); // Match the correct route
  },
  sellerSignup: async (userData) => {
    return post("/api/users/seller-signup", userData, false);
  },
  Login: async (userData) => {
    return post("/api/auth/login", userData, false);
  },
  GoogleLogin: async (code) => {
    return post("/api/auth/google/callback", code, false);
  },
  SeceretLogin: async (userData) => {
    return post("/api/users/login", userData, true);
  },
  SeceretLoginInEmployer: async (userData) => {
    return post("/api/employer/login", userData, true);
  },
  Logout: async (userData) => {
    return post("/api/auth/logout", userData, false);
  },
  forgotPassword: async (userData) => {
    return post("/api/auth/forgotpassword", userData, false);
  },
  resetPassword: async (token, data) => {
    return post(`/api/auth/resetpassword/${token}`, data, false);
  },
  visitors: async (visitordata) => {
    return post("/api/visitor", visitordata, false);
  },
  contactus: async (contactdata) => {
    return post("/api/contact", contactdata, false);
  },
  uploadcv: async (uploadcv) => {
    return post("/api/ai/uploadcv", uploadcv, false);
  },
  simpleupload: async (uploadcv) => {
    return post("/api/job/apply/candidate", uploadcv, false);
  },

  jobcreation: async (jobdata) => {
    return post("/api/job", jobdata, false);
  },
  getJobs: async () => {
    return get("/api/job", false);
  },
  getJobsByuserId: async (userId) => {
    return get(`/api/job/emp/${userId}`, false);
  },
  getJobsByAdmin: async () => {
    return get(`/api/job/admin`, true);
  },
  getJobsByReported: async () => {
    return get(`/api/job/reported-jobs`, false);
  },
  getJobsByReportedEmp: async (id) => {
    return get(`/api/job/reported-jobs/employer/${id}`, false);
  },
  getprofileByUserid: async (userId) => {
    return get(`/api/profile/by-user/${userId}`, false);
  },
  getProfileall: async () => {
    return get("/api/profile", false);
  },
  getProfileId: async (id) => {
    return get(`/api/profile/${id}`, false);
  },
  createProfile: async (profiledata) => {
    return post(`/api/profile`, profiledata, false);
  },
  updateProfile: async (profileid, profiledata) => {
    return put(`/api/profile/${profileid}`, profiledata, false);
  },
  updateDefaultProfile: async (profileid) => {
    return put(`/api/profile/${profileid}/set-default`, true);
  },
  updateProfileById: async (profileid, profiledata) => {
    return put(`/api/profile/${profileid}/update`, profiledata, false);
  },
  getSingleJob: async (jobid) => {
    return get(`/api/job/jobid/${jobid}`, false);
  },
  saveJob: async (applicantId, jobId) => {
    return post(`/api/job/save-job/${applicantId}`, { jobId });
  },
  unsavedJOb: async (applicantId, jobId) => {
    return del(`/api/job/unsave-job/${applicantId}/${jobId}`);
  },
  reportjob: async (reportdata) => {
    return post(`/api/reportjob`, reportdata, false);
  },
  deleteReportedJob: async (id) => {
    return del(`/api/job/reported-jobs/${id}`);
  },
  saveAllJob: async (applicantId) => {
    return get(`/api/job/saved-jobs/${applicantId}`);
  },
  jobwithdraw: async (id, jobdata) => {
    return post(`/api/job/withdraw/${id}`, jobdata, false);
  },
  jobInvite: async (id, jobdata) => {
    return post(`/api/invite/${id}`, jobdata, false);
  },
  getActiveJobByemp: async (id) => {
    return get(`/api/job/active/${id}`, false);
  },
  updateUser: async (userId, userData) => {
    return put(`/api/users/${userId}`, userData);
  },
  jobupdate: async (jobid, jobdata) => {
    return put(`/api/job/${jobid}`, jobdata);
  },

  deleteUser: async (userId) => {
    return del(`/users/${userId}`);
  },
  deleteAccount: async (userId) => {
    return del(`/api/users/delete-account/${userId}`);
  },

  deleteProfile: async (profileId) => {
    return del(`/api/profile/${profileId}`);
  },
  deletejob: async (jobId) => {
    return del(`/api/job/${jobId}`, false);
  },
  applyjob: async (data) => {
    return post(`/api/job/apply-job`, data, false);
  },
  generateai: async (data) => {
    return post(`/api/ai/generate-job-posting`, data, false);
  },
  generateresponsbility: async (data) => {
    return get(`/get-responsibilities/${data}`, false);
  },
  getofferings: async (companyname, jobtitle) => {
    return get(`/get-company-offerings/${companyname}/${jobtitle}`, false);
  },
  getapplications: async (applicantId, status) => {
    return get(`/api/applications/${applicantId}/${status}`, false);
  },
  UpdateAccount: async (userData) => {
    return put(`/api/users/updateAccount/`, userData, true);
  },
  UpdateUser: async (id, userData) => {
    return put(`/api/users/update/${id}`, userData, true);
  },
  getJobProfileAnalysis: async (jobid, profileid) => {
    return get(
      `/api/ai/analysis-profile?jobid=${jobid}&profileid=${profileid}`
    );
  },
  getApplicationsByid: async (id) => {
    return get(`/api/applications/byid/${id}`);
  },
  getAllApplicationByEmp: async (userid) => {
    return get(`/api/applications/by-employer/${userid}`);
  },
  getAllApplication: async (userid) => {
    return get(`/api/applications/admin/applications`, true);
  },
  getAllApplicationByJObId: async (jobid) => {
    return get(`/api/applications/byjob/${jobid}`);
  },
  UpdateApplicationStatus: async (id, status) => {
    return put(`/api/applications/status/${id}`, status, false);
  },
  PostApplicationStatus: async (id, status) => {
    return post(`/api/applications/status/${id}`, status, false);
  },
  UpdateJObStatus: async (id, status) => {
    return put(`/api/job/status/${id}`, status, false);
  },
  searchjob: async (data) => {
    return post(`/api/job/search-job`, data, false);
  },
  searchJob: async (data) => {
    const queryString = new URLSearchParams(data).toString(); // Converts data to query string format
    return get(`/api/job/search?${queryString}`, null, false); // Use null as data for GET request
  },
  addCousre: async (data) => {
    return post(`/api/course`, data, false);
  },
  editCourse: async (courseid, coursedata) => {
    return put(`/api/course/${courseid}`, coursedata, false);
  },
  getAllCourses: async () => {
    return get(`/api/course`, false);
  },
  getCourses: async () => {
    return get(`/api/course/seller`, true);
  },
  getCoursebyId: async (id) => {
    return get(`/api/course/${id}`, false);
  },
  getCourseDeatils: async (title) => {
    return get(`/api/ai/courseinfo/${title}`, false);
  },
  deleteCourse: async (id) => {
    return del(`/api/course/${id}`, false);
  },
  getjobForIndusteies: async () => {
    return get(`/api/job/industries`, false);
  },
  getjobForIndustry: async (industry) => {
    return get(`/api/job?industry=${industry}`, false);
  },
  getjobApplicantId: async (applicantId) => {
    return get(`/api/job?applicantId=${applicantId}`, false);
  },
  addStaff: async (data) => {
    return post(`/api/staff`, data, false);
  },
  editStaff: async (staffid, staffdata) => {
    return put(`/api/staff/${staffid}`, staffdata, false);
  },
  getStaff: async (employerId) => {
    return get(`/api/staff/employer/${employerId}`, false);
  },
  getStaffbyId: async (id) => {
    return get(`/api/staff/${id}`, false);
  },
  deleteStaff: async (id) => {
    return del(`/api/staff/${id}`, false);
  },
  getSuperAdmindashboard: async () => {
    return get(`/api/users/count/roles`, false);
  },
  getemployeedashboard: async (employerId) => {
    return get(`/api/employer/dashboard`, true);
  },
  getsellerdashboard: async () => {
    return get(`/api/seller/dashboard`, true);
  },

  getcandidatedashboard: async () => {
    return get(`/api/applicants/dashboard`, true);
  },
  shortlistApplicant: async (applicantId) => {
    return put(`/api/applicants/shortlist/${applicantId}`);
  },
  rejectApplicant: async (applicantId) => {
    return put(`/api/applicant/${applicantId}/reject`);
  },
  enroll: async (formData) => {
    return post(`/api/enrollment-enquiry`, formData);
  },
  addMessageEnquiry: async (enrollmentId, formData) => {
    return post(`/api/enrollment-enquiry/message/${enrollmentId}`, formData);
  },
  addNotesEnquiry: async (enrollmentId, formData) => {
    return post(`/api/enrollment-enquiry/note/${enrollmentId}`, formData);
  },
  addMessage: async (applicationsId, formData) => {
    return post(`/api/applications/message/${applicationsId}`, formData);
  },
  addNotes: async (applicationsId, formData) => {
    return post(`/api/applications/note/${applicationsId}`, formData);
  },
  getMessageEnquiry: async (enrollmentId) => {
    return get(`/api/enrollment-enquiry/message/${enrollmentId}`);
  },
  getNotesEnquiry: async (enrollmentId) => {
    return get(`/api/enrollment-enquiry/notes/${enrollmentId}`);
  },
  getEnrollmentList: async (id) => {
    return get(`/api/enrollment-enquiry/user/${id}`, true);
  },
  getSingleEnroll: async (id) => {
    return get(`/api/enrollment-enquiry/${id}`);
  },
  updateEnrollstatus: async (id, status) => {
    return put(`/api/enrollment-enquiry/${id}`, status, false);
  },
  getMessages: async (participantId) => {
    return get(`/api/messages/conversations/${participantId}`);
  },
  getMessagesByApplications: async (id) => {
    return get(`/api/messages/${id}`);
  },
  getMessagesByEnrollment: async (id) => {
    return get(`/api/messages/enrollment/${id}`, false);
  },
  addBlog: async (data) => {
    return post(`/api/blog`, data, false);
  },
  editBlog: async (blogid, data) => {
    return put(`/api/blog/${blogid}`, data, false);
  },
  updateBlogstatus: async (blogid, data) => {
    return put(`/api/blog/${blogid}/status`, data, false);
  },
  getBlogs: async () => {
    return get(`/api/blog`, false);
  },
  getBlogbyId: async (id) => {
    return get(`/api/blog/${id}/byid`, false);
  },
  getBlogbySlug: async (slug) => {
    return get(`/api/blog/${slug}/byslug`, false);
  },
  deleteBlog: async (id) => {
    return del(`/api/blog/${id}`, false);
  },
  createSitemap: async (data) => {
    return post(`/api/sitemap`, data, false);
  },
  updateSiteMap: async (data) => {
    return post(`/api/sitemap/update-sitemap`, data, false);
  },
  updateRobotTxt: async (data) => {
    return post(`/api/update-robots`, data, false);
  },
  getApplicationsByProfileId: async (id) => {
    return get(`/api/profile/${id}/application`, false);
  },
  getTicketByUserId: async () => {
    return get(`/api/ticket/by-user`, true);
  },
  addTicket: async (data) => {
    return post(`/api/ticket`, data, false);
  },
  editTicket: async (id, data) => {
    return put(`/api/ticket/${id}`, data, false);
  },
  getTicket: async () => {
    return get(`/api/ticket`, true);
  },
  getTicketbyId: async (id) => {
    return get(`/api/ticket/${id}`, false);
  },
  deleteTicket: async (id) => {
    return del(`/api/ticket/${id}`, false);
  },
  addMessageTicket: async (ticketid, formData) => {
    return post(`/api/ticket/message/${ticketid}`, formData);
  },
  addNotesTicket: async (ticketid, formData) => {
    return post(`/api/ticket/note/${ticketid}`, formData);
  },
  PostVisitor: async (formData) => {
    return post(`/api/visitor`, formData);
  },
  getVisitor: async () => {
    return get(`/api/visitor`, true);
  },
  deleteVisitor: async (id) => {
    return del(`/api/visitor/${id}`, false);
  },
  deleteVisitors: async (data) => {
    return post(`/api/visitor/deletemany`, data);
  },
  PostCompanybyEmp: async (data) => {
    return post(`/api/company`, data, false);
  },
  getCompanybyEmp: async (id) => {
    return get(`/api/company/${id}`, false);
  },
  cancelInterview: async (id, data) => {
    return post(`/api/applications/cancel-interview/${id}`, data, false);
  },
  confirmInterview: async (id) => {
    return post(`/api/applications/confirm-interview/${id}`, false);
  },
  socialLogin: `${BASE_URL_PLATFORM}/api/auth/google`,
};

export default api;
