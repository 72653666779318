import React, { useEffect, useState, useRef } from "react";
import Candidatesimage from "../../image/Candidate14.png";
import CreateMultipleProfiles from "../../image/imagecandidates.png";
import CvMultipleProfiles from "../../image/CVsectionimage.png";
import Firstimage from "../../image/codicon_git-stash-apply.png";
import { usePopup } from "../Common/PopupContext";
import Vectorcandidate from "../../image/Vectorcandidate.png";
import Stepsecond from "../../image/healthicons_group-discussion-meeting.png";
import Stepthree from "../../image/fluent_eye-tracking-24-regular.png";
import Stepfour from "../../image/fluent_communication-16-regular.png";
import Stepfive from "../../image/fluent-mdl2_onboarding.png";
import CandidateApply from "../popup/CandidateApply";
import { getUserData } from "../../helpers/utils";
import { useHistory, useParams } from "react-router-dom";
import VisitorTracker from "../Common/VisitorTracker.js";
import Login from "../Website/Login";
const Candidates = () => {
  const { openPopup } = usePopup();
  // const [popupComponent, setPopupComponent] = useState(null);
  const history = useHistory();
  const toastRef = useRef();
  const profiledata = useParams();
  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  useEffect(() => {
    if (!isEmpty(profiledata)) {
      const popup = (
        <CandidateApply
          onClose={() => handleClosePopup("Popup closed")}
          profileid={profiledata.profileid}
        />
      );
      //setPopupComponent(popup);
      openPopup(popup);
    }
  }, []);
  const User = getUserData();
  const handleCandidate = () => {};
  /* ======= For Popup ====== */
  const handleCandidateClick = () => {
    const popup = (
      <Login
        onClose={() => handleClosePopup("Popup closed")}
        candidate="candidate"
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handlecCreateProfileClick = () => {
    const popup = <Login onClose={() => handleClosePopup("Popup closed")} />;
    //setPopupComponent(popup);
    openPopup(popup);
  };
  // /* ======= Ends Popup ====== */
  return (
    <>
      <div className="hrp-main-section  candidates-page">
        <section className="hrp-section-1  hrp-container  ">
          <div className="hrp-sub-section">
            <div className="color-backgroud ">
              <div className="container m-auto color-backgroud  ">
                <div className=" md:px-8 px-4 lg:py-32  md:py-8 lg:flex md:flex items-center">
                  <div className="md:w-1/2 flex md:justify-center  xl:pl-0 lg:pl-10 md:py-0 py-10">
                    <div className="Candidates-main-Hirepros ">
                      <h1 className=" font-bold ">Welcome to Hirepros</h1>
                      <p className=" mb-6">Your gateway to a brighter career</p>
                      <button
                        className="px-8 py-2 get-started"
                        onClick={() => handleCandidateClick()}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                  <div className="md:w-1/2">
                    <img
                      src={Candidatesimage}
                      alt="Hirepros Illustration"
                      className=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-section-2  hrp-container  ">
          <div className="hrp-sub-section">
            <div className="container m-auto">
              <div className="md:flex  lg:flex justify-center items-center px-8 md:px-8  py-8">
                <div className="bg-white  md:w-2/5 flex items-center justify-center">
                  <img
                    src={CreateMultipleProfiles}
                    alt="Profile Image Overlay"
                  />
                </div>
                <div className="md:w-3/5 lg:px-10  xl:px-20    md:px-5  CreateMultiple">
                  <h1 className=" mb-2">Create Multiple Profiles</h1>
                  <p className=" mb-6">
                    Welcome to HirePros, your ultimate job portal for a seamless
                    job-hunting experience. At HirePros, candidates can create
                    multiple profiles tailored to various job opportunities.
                    Each profile can highlight different skill sets, making it
                    easy to apply for a wide range of positions. By simply
                    uploading your CV, you can customize profiles to emphasize
                    specific skills and experiences relevant to each job
                    application. Keeping your profile up-to-date is
                    straightforward, ensuring you always showcase your most
                    recent achievements and abilities. This increases your
                    chances of getting shortlisted by top employers. HirePros is
                    designed to streamline the application process, helping you
                    stand out in a competitive job market. Join HirePros today
                    and take control of your career journey with personalized,
                    skill-focused profiles that connect you with the best
                    opportunities.
                  </p>
                  <button
                    className="px-8 py-2   get-started"
                    onClick={handlecCreateProfileClick}
                  >
                    Create Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-section-3  hrp-container  ">
          <div className=" hrp-sub-section">
            <div className="container m-auto  ">
              <div className="md:flex lg:flex justify-center items-center px-8 2xl:px-10  ">
                <div className=" md:w-2/3 lg:px-20 md:px-5  CreateMultipleProfiles">
                  <h1 className=" font-bold mb-4">CV Upload</h1>
                  <p className=" mb-6">
                    Upload Upload your CV to receive notifications and invites
                    from top employers. Keep your profile updated to increase
                    your chances of getting noticed.
                  </p>
                  <button
                    className="px-8 py-2   get-started"
                    onClick={() => history.push("/upload-cv")}
                  >
                    CV Upload
                  </button>
                </div>
                <div className=" p-8 md:w-1/3  flex items-center">
                  <img src={CvMultipleProfiles} alt="Profile Image Overlay" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-section-4  hrp-container  ">
          <div className="hrp-sub-section">
            <div className="color-background-inner">
              <div className="container m-auto">
                <div className="flex justify-center items-center md:px-0 px-5 pt-16 py-8">
                  <div className="text-center  Discussion">
                    <h1>Job Application and Discussion</h1>
                    <p>
                      Apply for jobs directly through our platform and engage in
                      discussions with potential employers. Track your
                      application status and manage your communications
                      seamlessly.
                    </p>
                  </div>
                </div>
                <div className="relative vectorcandidate-main">
                  <div className="Vectorcandidate-image">
                    <img
                      className="m-auto"
                      src={Vectorcandidate}
                      alt="Vectorcandidate"
                    ></img>
                  </div>
                  <div className="lg:flex">
                    <div className="ApplyforJobs-outer firststep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Firstimage}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            <div className="ApplyforJobs-text">
                              Apply for Jobs
                            </div>
                          </div>
                        </div>

                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Browse through millions of job listings and apply to
                            the ones that match your skills and interests.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer secondstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepsecond}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            <div className="ApplyforJobs-text">
                              Job Discussions
                            </div>
                          </div>
                        </div>

                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Engage in discussions with employers and other job
                            seekers to gain insights and tips.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer thirdstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepthree}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            <div className="ApplyforJobs-text">
                              Application Tracking
                            </div>
                          </div>
                        </div>

                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Keep track of your job applications and get updates
                            on their status.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer fourthstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepfour}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            <div className="ApplyforJobs-text">
                              Interview Preparation
                            </div>
                          </div>
                        </div>

                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Access resources and tips to prepare for interviews
                            and increase your chances of getting hired.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer fifthstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepfive}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            <div className="ApplyforJobs-text">
                              Career Advice
                            </div>
                          </div>
                        </div>

                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Get advice and guidance on career development and
                            job search strategies.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-section-5  hrp-container  ">
          <div className="hrp-sub-section">
            <div className="bg-white py-16  Upload-your-CV">
              <div className="container mx-auto text-center">
                <h2 className=" font-bold mb-4">Get Hired with HirePros</h2>
                <p className="mb-6">
                  Our platform connects you with top employers looking for your
                  skills. Receive job offers and secure your next role with
                  ease. Start your journey to a successful career today.
                </p>
                <button
                  className="get-started  px-10 py-2"
                  onClick={() => history.push("/upload-cv")}
                >
                  Upload CV
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>{" "}
      <VisitorTracker />
    </>
  );
};
export default Candidates;
