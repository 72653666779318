import React, { useState, useRef, useEffect } from "react";
import api from "../../Api.js";
import { useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";

import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { getUserData } from "../../helpers/utils";
import DefaultImage from "../../image/upload-featured-image.jpg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const AddBlog = () => {
  const history = useHistory();
  const toastRef = useRef();
  const User = getUserData();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    html: "",
    featured_image: null,
    user_id: "",
    status: "draft",
    meta: {
      meta_description: "",
      meta_title: "",
      meta_keywords: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block"],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["link", "image", "video"],
      ["clean"],
      [{ table: [] }],
    ],
  };
  const handleChange = (e) => {
    if (typeof e === "string") {
      setFormData({
        ...formData,
        html: e,
      });
    } else {
      const { name, value } = e.target;
      if (name.startsWith("meta")) {
        setFormData({
          ...formData,
          meta: {
            ...formData.meta,
            [name]: value,
          },
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }

      if (name === "title") {
        handleChangeSlug(value);
      }
    }
  };
  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      status: selectedValue,
    });
  };
  const handleChangeSlug = (title) => {
    if (typeof title !== "string") {
      console.error("Title is not a string:", title);
      return;
    }

    const slug = title
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    setFormData((prevData) => ({
      ...prevData,
      slug,
    }));
  };

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        BASE_URL_PLATFORM + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevData) => ({
        ...prevData,
        featured_image: response.data.imagePath,
      }));
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.user_id = User?._id;
    //console.log(formData);
    try {
      const response = await api.addBlog(formData);
      if (response.status === 201) {
        toastRef.current.addToast("Blog Created Successffully", "success");
        setLoading(false);
        setTimeout(() => {
          history.push(`./edit-blog/${response?.blog?._id}`);
        }, 1000);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
    } finally {
      setLoading(false);
    }
  };
  const status = [
    { id: "draft", text: "Draft" },
    { id: "published", text: "Published" },
    { id: "archived", text: "Archived" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, setShowInput]);
  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white shadow-md rounded-lg">
      <h2
        className="text-3xl font-bold mb-6 flex justify-center item-center"
        style={{ color: "#003366" }}
      >
        Create New Blog Post
      </h2>
      <div className="grid lg:grid-cols-2 lg:gap-x-5">
        <div className="animated-form profileheadlinediv">
          <div className="form-group">
            <input
              type="text"
              name="title"
              placeholder=""
              value={formData.title}
              onChange={(e) => {
                handleChange(e);
              }}
              className="border rounded w-full py-2 px-3 text-gray-700"
              id="title"
            />
            <label htmlFor="title" className="profileheadline">
              Title
            </label>
          </div>
        </div>{" "}
        <div className="animated-form profileheadlinediv">
          {showInput || !formData.slug ? (
            <div ref={inputRef} className="form-group">
              <input
                type="text"
                name="slug"
                placeholder=""
                value={formData.slug}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="slug"
              />
              <label htmlFor="slug" className="profileheadline">
                Slug
              </label>
            </div>
          ) : (
            <div className="flex justify-between">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  className="hrp-slug-blog-link"
                  href={`https://hirepros.co.uk/blogs/${formData.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`https://hirepros.co.uk/blogs/${formData.slug}`}
                </a>
                <FeatherIcon
                  icon="edit-2"
                  className="edit-icon"
                  width={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowInput(true)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="animated-form profileheadlinediv hrp-editor-section">
        <div className="form-group">
          <ReactQuill
            value={formData.html}
            modules={modules}
            onChange={handleChange}
            className="rounded w-full py-2 px-3 text-gray-700"
            id="html"
            theme="snow"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 lg:gap-x-5">
        <div className="animated-form mt-3">
          <div
            className="relative job-search-label-section singleselect2 statusenroll"
            style={{ width: "100%", textAlign: "center" }}
          >
            <Select2
              data={status}
              className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
              defaultValue={formData?.status}
              onChange={(e) => handleStatusChange(e)} //
              options={{
                placeholder: "Select Status",
                width: "100%",
                theme: "classic",
              }}
            />
          </div>
        </div>

        <div>
          <div
            className=" flex m-auto hrp-upload-image"
            onChange={(e) => handleUpload(e.target.files[0])}
          >
            <div
              className=" flex justify-center w-full"
              style={{ cursor: "pointer" }}
            >
              {formData.featured_image ? (
                <div className="hrp-upload-image-border">
                  <img
                    className="DefaultImage-logo"
                    src={
                      BASE_URL_PLATFORM + "/uploads/" + formData.featured_image
                    }
                    alt="Uploaded"
                  />
                </div>
              ) : (
                <div className="">
                  <img src={DefaultImage} className="w-full " alt="Default" />
                </div>
              )}
            </div>

            <label htmlFor="featured_image" className=" hrp-upload-image-label">
              <input
                type="file"
                accept="image/*"
                id="featured_image"
                name="featured_image"
                className="w-full  p-2"
              />
            </label>
          </div>
          <div
            className="flex justify-center py-3  font-semibold text-sm "
            style={{ color: "#003366" }}
          >
            Featured Image
          </div>
        </div>
      </div>
      <div className="metadetails ">
        <h2
          className="text-xl font-semibold mt-2 underline"
          style={{ color: "#003366" }}
        >
          Meta Information
        </h2>
        <div className="grid lg:grid-cols-2 lg:gap-x-5">
          <div className="animated-form profileheadlinediv">
            <div className="form-group">
              <input
                type="text"
                name="meta_title"
                placeholder=""
                value={formData.meta?.title}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="meta_title"
              />
              <label htmlFor="meta_title" className="profileheadline">
                Meta Title
              </label>
            </div>
          </div>
          <div className="animated-form profileheadlinediv">
            <div className="form-group">
              <input
                type="text"
                name="meta_keywords"
                placeholder=""
                value={formData.meta?.meta_keywords}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="border rounded w-full py-2 px-3 text-gray-700"
                id="meta_keywords"
              />
              <label htmlFor="meta_keywords" className="profileheadline">
                Meta Keyword
              </label>
            </div>
          </div>
        </div>
        <div className="animated-form profileheadlinediv">
          <div className="form-group">
            <textarea
              name="meta_description"
              placeholder=""
              value={formData.meta?.meta_description}
              onChange={handleChange}
              className="border rounded  w-full py-2 px-3 text-gray-700"
              id="meta_description"
              rows={7}
            ></textarea>
            <label
              htmlFor="meta_description"
              className="profileheadline description-label"
            >
              Meta Description
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-between  gap-3 mt-5">
        <button
          type="submit"
          className="hrp-find-course-btn px-8 py-2 rounded-full"
        >
          Submit Blog
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </form>
  );
};

export default AddBlog;
