// Login.js
import React, { useState, useRef } from "react";
import api from "../../Api";
import { NavLink, useHistory } from "react-router-dom";
import { usePopup } from "../Common/PopupContext";
import "../../App.css";
import { encryptData } from "../../helpers/encryption";
import ForgotPassword from "./ForgotPassword";
import CustomToastContainer from "../Common/CustomToastContainer";
import VisitorTracker from "../Common/VisitorTracker.js";
import FeatherIcon from "feather-icons-react";
const Login = ({ redirect_url }) => {
  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const { openPopup } = usePopup();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const toastRef = useRef();
  const handleKeepMeLoggedInChange = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }
    setLoading(true);
    try {
      const userData = { email, password };
      const response = await api.Login(userData);
      const { token, refreshToken } = response;
      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        const { accessToken, refreshToken, _id, role } = response;
        const encryptedUserData = encryptData(response);
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (keepMeLoggedIn) {
          localStorage.setItem("user", encryptedUserData);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
        } else {
          sessionStorage.setItem("user", encryptedUserData);
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("refreshToken", refreshToken);
        }

        setTimeout(() => {
          var redirectdata = localStorage.getItem("redirect_url");
          if (
            redirectdata !== undefined &&
            redirectdata !== "" &&
            redirectdata !== null
          ) {
            setLoading(false);
            window.location.href = redirectdata;
            localStorage.removeItem("redirect_url");
          } else if (redirect_url !== null && redirect_url !== undefined) {
            setLoading(false);
            window.location.href = redirect_url;
          } else {
            setLoading(false);
            window.location.href = "/dashboard";
          }
        }, 2000);
      } else if (response.status == 400) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toastRef.current.addToast(error.response.data.msg, "error");
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    openPopup();
  };
  const socialLogin = async (e) => {
    e.preventDefault();
    const googleAuthUrl = () => {
      const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // process.env.GOOGLE_CLIENT_ID;
      const googleCallbackUrl = process.env.REACT_APP_GOOGLE_CALLBACK_URL; // process.env.REACT_APP_GOOGLE_CALLBACK_URL;
      const scope = "profile email";
      const redirectUri = encodeURIComponent(googleCallbackUrl);
      const state = JSON.stringify({
        role: "applicant",
      });
      return `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(
        state
      )}&access_type=offline&prompt=consent`;
    };
    window.location.href = googleAuthUrl();
  };

  const handleforgot = () => {
    setLoading(true);

    if (redirect_url != undefined) {
      const popup = (
        <ForgotPassword
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={redirect_url}
        />
      );
      openPopup(popup);
    } else {
      history.push("./forgotpassword");
      window.location.reload();
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <div className="login_inform login_inform-page h-screen	 ">
        <div className="flex justify-center items-center login_inform-inner">
          <div className="  form_upto m-8 loginDiv-page">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              <div className="mb-1 text-center">
                <div className="text-center HirePros-login-heading ">
                  <h2 className="pt-8  ">Hi, Welcome Back !</h2>
                </div>
              </div>
              {isMessage && (
                <button className="btn-loginmessage" type="text" danger>
                  {isMessage}
                </button>
              )}
              <form
                className="login-form-section animated-form"
                onSubmit={handleSubmit}
              >
                <div className="mb-1 form-group">
                  <input
                    className="  border rounded w-full py-2 px-3 text-gray-700  "
                    type="text"
                    id="email"
                    placeholder=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email" className="Email-label">
                    Email
                  </label>
                </div>
                <div className=" form-group">
                  <input
                    id="password"
                    className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                    type={showPassword ? "text" : "password"}
                    placeholder=""
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="password-label">
                    Password
                  </label>
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      <FeatherIcon
                        icon="eye-off"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600"
                      checked={keepMeLoggedIn}
                      onChange={handleKeepMeLoggedInChange}
                    />
                    <span className="ml-2 text-gray-700">
                      Keep me logged in
                    </span>
                  </label>
                  <NavLink
                    className="forgot-pass-link color"
                    to="/forgotpassword"
                    onClick={() => handleforgot()}
                  >
                    Forgot Password?
                  </NavLink>
                </div>
                {validationMessage && (
                  <div className="mb-4 text-red-500">{validationMessage}</div>
                )}
                <div>{isMessage != "null" ? isMessage : ""}</div>
                <div className="">
                  <button
                    className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {isLoading ? "Loading..." : "Log in"}
                  </button>
                </div>
                <div className="line-after-login my-2">
                  <div className="line-after-login-inner">
                    <div className="line-after-login-first"></div>
                    <div className="line-after-login-or">or</div>
                    <div className="line-after-login-second"></div>
                  </div>
                </div>
                <div className="mb-6">
                  <button
                    className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={socialLogin}
                  >
                    {isLoading ? "Loading..." : "Sign in with google"}
                  </button>
                </div>
                <div className="keepForget text-center">
                  <p style={{ color: "#003366 " }}>
                    Don't have an account?
                    <span>
                      <NavLink
                        className="forgot-pass-link color cursor-pointer	"
                        to="/register"
                      >
                        Signup
                      </NavLink>
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <VisitorTracker />
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Login;
