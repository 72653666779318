import React, { useState, useEffect, useRef } from "react";
import UploadCVimage from "../../image/bg_upload_CV.png";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import VisitorTracker from "../Common/VisitorTracker.js";
import { usePopup } from "../Common/PopupContext";
import ApplicationSucess from "../popup/ApplicationSuccess";
const UploadCV = () => {
  const [error, setError] = useState(null);
  const { openPopup } = usePopup();
  const toastRef = useRef();
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  var domainpath = process.env.REACT_APP_DOMAIN;
  const handleClosePopup = (message) => {
    openPopup();
  };

  const handleCVUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    // Add the file to FormData
    formData.append("resume", e.target.files[0]);

    try {
      // Upload the CV
      const res = await api.uploadcv(formData);

      // Check if the response is successful (assuming status 200 means success)
      if (res?.statusCode === 200) {
        res.joblink = domainpath;

        // Send an email with the uploaded CV data
        const send_email = await api.simpleupload(res);

        // Hide loader
        setLoading(false);

        // Display success popup
        const popup = (
          <ApplicationSucess
            onClose={() => handleClosePopup("Popup closed")}
            message={send_email}
          />
        );
        openPopup(popup);

        // Set the uploaded file name
        setUploadedFileName(e.target.files[0].name);
      } else {
        // Handle cases where the response is not successful
        setError(res?.message || "Upload failed with an unknown error.");
        setLoading(false);
      }

      // Clear any existing error
      setError(null);
    } catch (err) {
      // Catch any error during the upload or email sending process
      setLoading(false);

      // Extract the error message from the response or use a default one
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "An error occurred while uploading the file.";

      // Set the error state
      setError(errorMessage);

      // Display a toast with the error message
      toastRef.current.addToast(errorMessage, "error");
    }
  };
  return (
    <>
      <div className="upload-cv-main-outer section-1-uploade-cv">
        <div className="container m-auto ">
          <section className="sub-section-1-uploade-cv">
            <div className="UploadCV-border ">
              <div className=" md:flex items-center upload-cv-main justify-center p-10">
                <div className="upload-cv-section md:w-1/2">
                  <div className="upload-cv-text">
                    <h1> Upload Your CV </h1>
                    <h2>
                      Easily upload your CV to get started. Providing your CV
                      helps us recommend suitable job opportunities and
                      streamline your job application process.
                    </h2>
                  </div>
                  <div className="upload-section">
                    <input
                      type="file"
                      id="file-upload"
                      hidden
                      accept=".pdf, .docx"
                      onChange={handleCVUpload}
                    />
                    <label htmlFor="file-upload" className="upload-label">
                      Upload CV
                    </label>

                    {loading && <p>Uploading...</p>}
                    {uploadedFileName && !loading && (
                      <p className="upload-success-message">
                        Uploaded: {uploadedFileName}
                      </p>
                    )}
                    {error && !loading && (
                      <p className="upload-error-message">Error: {error}</p>
                    )}

                    <p className="supported-formats">
                      Supported Formats: doc, docx, rtf, pdf, up to 2 MB
                    </p>
                  </div>
                </div>
                <div className="md:w-1/2 UploadCVimage-section">
                  <img
                    className=""
                    src={UploadCVimage}
                    alt="UploadCVimage"
                  ></img>
                </div>
              </div>
            </div>
          </section>
          <section className="sub-section-2-uploade-cv">
            <div className="container m-auto">
              <div className="upload-cv-main py-14 px-10">
                <div className="flex  grid md:grid-cols-3 md:grid-cols-1  md:gap-5 ">
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">1</div>
                    <div className="UploadCV-content">
                      <h2>Upload Your CV</h2>
                      <p>
                        Start by uploading your most recent CV. This helps us
                        understand your qualifications and experience.
                      </p>
                    </div>
                  </div>
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">2</div>
                    <div className="UploadCV-content">
                      <h2>Build Your Profile</h2>
                      <p>
                        Create a detailed profile by filling in your personal
                        information, skills, and career preferences to stand out
                        to potential employers.
                      </p>
                    </div>
                  </div>
                  <div className="UploadCV-card">
                    <div className="UploadCV-circle">3</div>
                    <div className="UploadCV-content">
                      <h2> Apply for Job Matches</h2>
                      <p>
                        Based on your profile, we match you with suitable job
                        opportunities. Review and apply to the jobs that best
                        fit your career goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <VisitorTracker />
        <CustomToastContainer ref={toastRef} />
      </div>
    </>
  );
};
export default UploadCV;
