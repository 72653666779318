import React, { useState, useRef } from "react";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";

import FeatherIcon from "feather-icons-react";
const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setSelectedRole] = useState("applicant");

  const toastRef = useRef();
  const [validationMessage, setValidationMessage] = useState("");
  const socialLogin = async (e) => {
    e.preventDefault();
    const googleAuthUrl = () => {
      const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // process.env.GOOGLE_CLIENT_ID;
      const googleCallbackUrl = process.env.REACT_APP_GOOGLE_CALLBACK_URL; // process.env.REACT_APP_GOOGLE_CALLBACK_URL;
      const scope = "profile email";
      const redirectUri = encodeURIComponent(googleCallbackUrl);
      const state = JSON.stringify({
        role: role === "employer" ? "employer" : "applicant",
      });
      return `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(
        state
      )}&access_type=offline&prompt=consent`;
    };
    window.location.href = googleAuthUrl();
  };
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setValidationMessage("Name is required.");
      return;
    }

    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!phone) {
      setValidationMessage("Phone number is required.");
      return;
    }

    if (role === "employer" && !company) {
      setValidationMessage("Company name is required.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const name = `${firstName} ${lastName}`;
      const userData = {
        firstName,
        lastName,
        name,
        email,
        password,
        phone,
      };
      let response;
      if (role === "employer") {
        response = await api.employerSignup(userData);
      } else if (role === "applicant") {
        response = await api.applicantSignup(userData);
      }
      if (response.status !== 400) {
        toastRef.current.addToast("User Created Successfully", "success");
        setLoading(false);
        setTimeout(() => {
          document.getElementById("step_2").style = "display:none";
          document.getElementById("step_1").style = "display:block";
        }, 1000);
      }
    } catch (error) {
      toastRef.current.addToast(error.response.data.msg, "error");
      setLoading(false);
      console.error(error);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setPhone(numericValue);
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="login_inform login_inform-page h-screen	">
      {/* Registration form */}
      <div className="flex justify-center items-center login_inform-inner ">
        <div className="  loginDiv m-8" id="">
          <div className="flex justify-center  items-center login_inform-inner">
            <div className="w-full">
              <div className="     px-6 pt-4 pb-6 mb-4">
                <div className="mb-1 text-center HirePros-login-heading">
                  <h2 className="my-6">Create Account</h2>
                </div>
                <div className="register-user-select">
                  <div className="flex justify-center items-center w-full">
                    <div className="relative inline-flex items-center bg-blue-100 rounded-full w-full">
                      <span
                        className={`px-4 py-2 rounded-full cursor-pointer w-1/2 ${
                          role === "applicant"
                            ? "bg-white active-border-btn "
                            : ""
                        }`}
                        onClick={() => setSelectedRole("applicant")}
                      >
                        Applicant
                      </span>
                      <span
                        className={`px-4 py-2 rounded-full cursor-pointer w-1/2 ${
                          role === "employer"
                            ? "bg-white active-border-btn "
                            : ""
                        }`}
                        onClick={() => setSelectedRole("employer")}
                      >
                        Employer
                      </span>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={handleRegisterSubmit}
                  className="register-form-section animated-form"
                >
                  <div
                    className={`mb-2 ${"md:grid md:grid-cols-2 md:gap-x-5"}`}
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        id="firstName"
                        placeholder=""
                        onChange={(e) => setFirstName(e.target.value)}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="firstName" className="form-input-label">
                        First Name
                      </label>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        id="lastName"
                        placeholder=""
                        onChange={(e) => setLastName(e.target.value)}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="lastName" className="form-input-label">
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder=""
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                  <div
                    className={`mb-2 ${
                      role === "employer"
                        ? "md:grid md:grid-cols-2 md:gap-x-5"
                        : "md:grid md:grid-cols-1 md:gap-x-5"
                    }`}
                  >
                    <div
                      className={`form-group ${
                        role !== "employer" ? "col-span-2" : ""
                      }`}
                    >
                      <input
                        type="number"
                        id="phone"
                        name="phone"
                        required
                        value={phone}
                        onChange={(e) => handleChange(e)}
                        placeholder=""
                        maxLength={13}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="phone" className="form-input-label">
                        Phone
                      </label>
                    </div>

                    {role === "employer" && (
                      <div className="form-group">
                        <input
                          id="company"
                          type="text"
                          onChange={(e) => setCompany(e.target.value)}
                          placeholder=""
                          className="shadow w-full border rounded py-2 px-3"
                        />
                        <label htmlFor="company" className="form-input-label">
                          Company
                        </label>
                      </div>
                    )}
                    <div
                      className={`form-group ${
                        role === "employer" ? "col-span-1" : "col-span-1"
                      }`}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=""
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="password" className="form-input-label">
                        Password
                      </label>
                      <span
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      >
                        {showPassword ? (
                          <FeatherIcon
                            icon="eye-off"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-500"
                            viewBox="0 0 576 512"
                          >
                            <path
                              fill="currentColor"
                              d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                            />
                          </svg>
                        )}
                      </span>
                    </div>

                    <div
                      className={`form-group ${
                        role === "employer" ? "col-span-1" : "col-span-1"
                      }`}
                    >
                      <input
                        id="cpassword"
                        type="password"
                        placeholder=""
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="shadow w-full border rounded py-2 px-3"
                      />
                      <label htmlFor="cpassword" className="form-input-label">
                        Confirm Password
                      </label>
                    </div>
                  </div>

                  {error && (
                    <p className="text-red-500 text-xs italic">{error}</p>
                  )}
                  <div className=" register-promotional-emails mb-6">
                    <input type="checkbox" name="terms" required />
                    <div className="terms-policy">
                      I agree <a href="/terms-conditions"> Terms condation </a>{" "}
                      & <a href="/privacy-policy"> Privacy Policy</a>
                    </div>
                  </div>
                  <div className="Forgot_00">
                    <div className="">
                      <button
                        className="  text-white py-2  px-4 rounded w-full  register-form-btn"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="line-after-login my-2">
                      <div className="line-after-login-inner">
                        <div className="line-after-login-first"></div>
                        <div className="line-after-login-or">or</div>
                        <div className="line-after-login-second"></div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <button
                        className=" text-white login-google-btn  py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={socialLogin}
                      >
                        {isLoading ? "Loading..." : "Sign Up with google"}
                      </button>
                    </div>
                    <div className="text-center Any-Account-section">
                      <p>
                        Already have an account?
                        <a
                          href="/login"
                          className="cursor-pointer"
                          style={{ color: "#003366" }}
                        >
                          Login here.
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default RegisterForm;
