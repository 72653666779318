import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";
const domainpath = process.env.REACT_APP_DOMAIN;
const Savedjobs = () => {
  const User = getUserData();
  const [data, setData] = useState([]);
  useEffect(() => {
    const userid = User._id;
    const status = "Interviewed";
    async function getjobs() {
      try {
        const response = await api.getapplications(userid, status);
        if (response.status !== 400) {
          setData(response);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getjobs();
  }, []);
  const columns = [
    {
      name: "Job Title",
      selector: (row) => (
        <div>
          <div>
            <a
              href={domainpath + "/jobs/" + row?.jobId?._id}
              target="_blank"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.jobId?.title}
            </a>
          </div>
          <div>{row.jobId?.company.name}</div>
        </div>
      ),
      sortable: true,
      width: "30%",
    },
    {
      name: "Interview Date",
      selector: (row) => row.interviewDate,
      sortable: true,
      width: "20%",
    },
    {
      name: "Interview Time",
      selector: (row) => row.interviewTime,
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="lg:flex gap-1 xl:py-0 py-2 m-auto">
          <button className="manage-edit-btn text-white  xl:px-10 lg:px-5 px-9 md:px-9 py-2">
            Yes
          </button>
          <button className="manage-edit-btn text-white xl:px-6 px-6 lg:px-4 py-2 lg:mt-0 md:mt-1 mt-2 xl:mt-0">
            Cancel
          </button>
        </div>
      ),
      width: "30%",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
          Interviewed Jobs{" "}
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default Savedjobs;
