import React, { useState, useRef } from "react";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";

const CancelInterview = ({ onClose, applicationId }) => {
  const [loading, setLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [error, setError] = useState("");
  const User = getUserData();
  const toastRef = useRef();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const handleCancelInterview = async (e) => {
    e.preventDefault();
    setLoading(true);

    const reason = cancelReason === "Other" ? otherReason : cancelReason;
    const data = {
      reason,
    };
    try {
      const res = await api.cancelInterview(applicationId, data);
      if (res.status === 200) {
        toastRef.current.addToast(
          "Interview canceled successfully!",
          "success"
        );
        onClose("");
        setError("");
        window.location.reload();
      }
    } catch (err) {
      setError(err.response?.data?.message);
      toastRef.current.addToast(err.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

 
  const cancellationReasons = [
    "Position Filled",
    "Interview No Longer Required",
    "Rescheduled to a Different Date",
    "Applicant Withdrew Application",
    "Interviewer's Unavailability",
    "Technical Issues",
    "Change in Job Requirements",
    "Budget Constraints",
    "Applicant Did Not Meet Prerequisites",
    "Applicant No Longer Interested",
    "Scheduling Conflicts",
    "Interview Replaced by a Different Process",
    "Position Cancelled or Frozen",
    "Other",
  ];
  const handleCheckboxChange = (e) => {
    const reason = e.target.value;
    setCancelReason(reason);
    if (e.target.checked) {
      setSelectedReasons((prevReasons) => [...prevReasons, reason]);
    } else {
      setSelectedReasons((prevReasons) =>
        prevReasons.filter((r) => r !== reason)
      );
    }

    // Clear the `otherReason` field if "Other" is deselected
    if (reason === "Other" && !e.target.checked) {
      setOtherReason("");
    }
  };
  return (
    <div className="w-4/5 flex justify-center h-screen  login_inform-inner m-auto p-20">
      <div className="flex">
        <div className="form_upto m-3 loginDiv reportjob-width" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4 overflow-scroll  h-svh overflow-x-hidden custom-scrollbar">
            <div className="container mx-auto quickview-page ">
              <form
                onSubmit={handleCancelInterview}
                className="animated-form report-job-form"
              >
                <h2>Report Job</h2>
                <div className="report-reasons">
                  {cancellationReasons.map((reason, index) => (
                    <div key={index} className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          value={reason}
                          checked={selectedReasons.includes(reason)}
                          onChange={handleCheckboxChange}
                        />
                        {reason}
                      </label>
                    </div>
                  ))}
                </div>{" "}
                {cancelReason === "Other" && (
                  <div className="form-group  col-span-2">
                    <textarea
                      id="description"
                      name="otherReason"
                      placeholder=""
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer"
                    />
                    <label className="form-input-label absolute top-2 left-4 transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600">
                      Additional Details:
                    </label>
                  </div>
                )}
                <button className="mt-2" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-8	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default CancelInterview;
