// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import { Header, EmployerHeader } from "./Header";
import Dashboard from "../Empolyers/Dasboard";
import { Employers } from "./dashboardmenus";
import { PopupProvider } from "../Common/PopupContext";
import Profile from "../Candidates/Profile";
import Messages from "../Candidates/Messages";
import AccountSettings from "../Candidates/AccountSettings";
import ManageUser from "../Candidates/Manageuser";
import Managejobs from "../Candidates/Managejobs";
import jobApplication from "../Empolyers/jobApplication.js";
import SiteSettings from "../Candidates/Sitesettings";
import CompanySetting from "../Candidates/CompanySetting";
import ManageApplication from "../Candidates/ManageApplication.js";
import Tickets from "../Empolyers/Tickets.js";
import SingleTicketPage from "../Empolyers/SingleTickets.js";
import ViewProfile from "../Empolyers/UserProfile.js";
import Jobpost from "../Empolyers/postJob.js";
import AddTickets from "../Empolyers/Addticket.js";
import EditTicket from "../Empolyers/EditTicket.js";
import Reports from "../Empolyers/Reportjob.js";

function EmployerLayout({ userRole }) {
  // const useLocalStorage = (key) => {
  //   const storedValue = localStorage.getItem(key);
  //   return storedValue;
  // };
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");

  useEffect(() => {}, [user]);
  let { path } = useRouteMatch(); // Get the base path for nested routes

  return (
    <PopupProvider>
      <div className="">
        <EmployerHeader />
        <div className=" container m-auto hrp-min-h-screen">
          <div className="w-full   hrp-min-width">
            {user ? <Employers /> : ""}
            <section className="mt-5">
              <div className="bg-white container JobCategories-heading-admin mb-20 m-auto">
                <Switch>
                  <Route exact path={path} component={Dashboard} />
                  {/* <Route path={`${path}/profile`} component={Profile} /> */}
                  <Route
                    path={`${path}/application/:applicationId`}
                    component={ViewProfile}
                  />
                  <Route path={`${path}/messages`} component={Messages} />
                  <Route path={`${path}/jobs`} component={Profile} />{" "}
                  <Route
                    path={`${path}/job/:jobid`}
                    component={jobApplication}
                  />
                  <Route
                    path={`${path}/site-settings`}
                    component={SiteSettings}
                  />
                  <Route
                    path={`${path}/company-setting`}
                    component={CompanySetting}
                  />
                  <Route path={`${path}/manage-user`} component={ManageUser} />
                  <Route path={`${path}/manage-jobs`} component={Managejobs} />
                  <Route
                    path={`${path}/account-settings`}
                    component={AccountSettings}
                  />
                  <Route
                    path={`${path}/ticket/:id`}
                    component={SingleTicketPage}
                  />
                  <Route
                    path={`${path}/job-applications`}
                    component={ManageApplication}
                  />
                  <Route path={`${path}/messages`} component={Messages} />
                  <Route path={`${path}/report/:id`} component={Reports} />
                  <Route path={`${path}/tickets`} component={Tickets} />
                  <Route path={`${path}/add-ticket`} component={AddTickets} />
                  <Route
                    path={`${path}/edit-ticket/:id`}
                    component={EditTicket}
                  />
                  <Route path={`${path}/jobpost/:id`} component={Jobpost} />
                  {/* Add other routes as needed */}
                </Switch>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default EmployerLayout;
