import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { decryptData } from "../../helpers/encryption";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";

const DeleteAccount = ({ onClose }) => {
  const history = useHistory();
  const toastRef = useRef();
  const [isMessage] = useState(null);
  const [deletionMessage, setDeletionMessage] = useState("");

  const User = getUserData();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const encryptedUserData = localStorage.getItem("user");
    if (encryptedUserData) {
      const userData = decryptData(encryptedUserData);
      if (userData && userData.accessToken) {
        history.push("/dashboard");
      }
    }
  }, [history]);

  const handleDelete = async (userid) => {
    if (!deletionMessage) {
      return;
    }
    setLoading(true);
    const response = await api.deleteAccount(`${userid}`);
    //console.log(response);

    if (response.status !== 500) {
      setLoading(false);
      toastRef.current.addToast(response.message, "success");
      setTimeout(() => {
        const adminAccessToken = Cookies.get("adminaccessToken");
        const adminRefreshToken = Cookies.get("adminrefreshToken");
        const adminUser = Cookies.get("adminuser");

        if (adminAccessToken && adminRefreshToken && adminUser) {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          Cookies.remove("user");
          Cookies.set("accessToken", adminAccessToken);
          Cookies.set("refreshToken", adminRefreshToken);
          Cookies.set("user", adminUser);
          Cookies.remove("adminaccessToken");
          Cookies.remove("adminrefreshToken");
          Cookies.remove("adminuser");
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          Cookies.remove("user");
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }
      }, 3000);
    } else {
      setLoading(false);
      toastRef.current.addToast(response.message, "error");
    }
  };

  return (
    <div className="lg:w-1/2 flex justify-center h-screen  login_inform-inner m-auto p-20">
      <div className="flex">
        <div className="form_upto loginDiv-width m-3 loginDiv" id="step_1">
          <div className="bg-white  shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <div className="mb-1 text-center py-10">
              <div className="text-center HirePros-login-heading ">
                <h2 className="  ">Confirm account deletion?</h2>
                <p>
                  We're sorry to see you go. Once your account is deleted, all
                  of your content will be permanently gone, including your
                  profile, stories, publications, notes, and responses. Deleting
                  your Medium account will not delete any Stripe account you
                  have connected to your Medium account. If you're not sure
                  about that, we suggest you deactivate or
                  contact yourfriends@medium.com instead.
                </p>
              </div>
            </div>
            {isMessage && (
              <button className="btn-loginmessage" type="text" danger>
                {isMessage}
              </button>
            )}
            <form className="login-form-section animated-form">
              <div className="mb-1 form-group">
                <input
                  className="border rounded w-full py-2 px-3"
                  type="text"
                  id="deletionMessage"
                  placeholder="Type 'Delete Confirm'"
                  value={deletionMessage}
                  onChange={(e) => {
                    setDeletionMessage(e.target.value);
                  }}
                />
                <label htmlFor="deletionMessage" className="Email-label ">
                  Confirm Deletion
                </label>
              </div>
              {!deletionMessage && (
                <p className="text-red-500 text-sm mt-1">
                  Please confirm deletion by typing the message.
                </p>
              )}
              <div className="mb-6 gap-2 flex">
                <button
                  className="text-white login-google-btn py-3 px-8 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => handleDelete(User?._id)}
                  disabled={!deletionMessage} // Disable if deletion message is empty
                >
                  Confirm
                </button>
                <button
                  className="text-white login-google-btn py-2 px-8  rounded  focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default DeleteAccount;
