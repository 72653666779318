import React, { useState } from "react";
import api from "../../Api";
import { useLocation } from "react-router-dom";
import { usePopup } from "../Common/PopupContext";
import Login from "../Website/Login";
import FeatherIcon from "feather-icons-react";
const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { openPopup } = usePopup();
  const handleClosePopup = () => {
    //console.log();
    openPopup();
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== newPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    var data = {
      newPassword,
    };
    try {
      const response = await api.resetPassword(token, data);
      if (response.status == 200) {
        setLoading(false);
        setSuccess("Password reset successfully. You can now log in.");
        setTimeout(() => {
          const popup = (
            <Login onClose={() => handleClosePopup("Popup closed")} />
          );
          openPopup(popup);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError("Failed to reset password. Please try again.");
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <div className="login_inform login_inform-page h-screen ">
        <div className="flex justify-center items-center login_inform-inner">
          <div className="forgotpass-form  form_upto m-8 loginDiv-page">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              <h2 className="text-center py-5">Reset Your Password</h2>
              <form onSubmit={handleSubmit} className="animated-form">
                <div className="mb-4 form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />

                  <label htmlFor="newPassword" className="password-label">
                    New Password
                  </label>
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      // Eye Slash Icon
                      <FeatherIcon
                        icon="eye-off"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      // Eye Icon
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="mb-4 form-group">
                  <input
                    type="password"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <label htmlFor="confirmPassword" className="password-label">
                    Confirm Password
                  </label>
                </div>
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
                <div className="text-center">
                  <button
                    type="submit"
                    className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                    disabled={loading}
                  >
                    {loading ? "Resetting..." : "Reset Password"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
