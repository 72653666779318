import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import api from "../../Api.js";
import { usePopup } from "../Common/PopupContext";
import bg_upload_CV from "../../image/jobpostimg.webp";
import DefaultImage from "../../image/Upload-Company-Logo.jpg";
import Generateai from "../popup/Generateai.js";
import { getUserData } from "../../helpers/utils";
import { useLocation } from "react-router-dom";
import Login from "../Website/Login";
import {
  jobSectors,
  jobCategories,
  jobSkills,
  qualifications,
} from "../../helpers/Object";
import VisitorTracker from "../Common/VisitorTracker.js";
import QualificationsTable from "./Qualification.js";
import SearchableDropdown from "../../helpers/SearchableDropdown";
const styles = {
  table: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #C6C6C6",
  },
  rowstyle: {
    borderBottom: "1px solid #C6C6C6",
  },
  header: {
    borderRight: "1px solid #C6C6C6",
    padding: "8px 20px",
    color: "#003366",
    backgroundColor: "#F1F4F7",
    fontWeight: "500",
    textAlign: "left",
  },
  categoryCell: {
    borderRight: "1px solid #C6C6C6",
    // borderBottom: "1px solid #C6C6C6",
    padding: "8px",
    fontWeight: "bold",
    verticalAlign: "top",
    backgroundColor: "#f9f9f9",
    width: "20%",
  },
  cell: {
    borderRight: "1px solid #C6C6C6",
    // borderBottom: "1px solid #C6C6C6",
    padding: "8px",
    width: "30%",
    verticalAlign: "top",
    position: "relative",
  },
  deleteQualificationCell: {
    padding: "10px",
    verticalAlign: "middle",
    textAlign: "center",
    position: "absolute",
    right: "-6%",
    bottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    padding: "5px",
    cursor: "text",
    color: "#3E3E3E",
    fontWeight: "500",
    fontSize: "14px",
  },
  text2: {
    padding: "5px",
    cursor: "text",
    color: "#3E3E3E",
    fontWeight: "400",
    fontSize: "14px",
  },
  borderButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    border: "none",
    cursor: "pointer",
    background: "#fff",
  },
  crossWithTd: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "top",
    justifyContent: "space-between",
    // borderRight: "1px solid #C6C6C6",
    // borderBottom: "1px solid #C6C6C6",
    padding: "8px",
  },
  addRowIconWrapper: {
    position: "absolute",
    bottom: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    zIndex: "999",
  },
  addRowIcon: {
    border: "none",
    cursor: "pointer",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    background: "green",
    borderRadius: "30px",
  },
  addButton: {
    padding: "5px 10px",
    border: "none",
    borderRadius: "40px",
    background: "rgb(0 51 102)",
    color: "rgb(255, 255, 255)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
};
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const JobForm = () => {
  const toastRef = useRef();
  const User = getUserData();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [jobId, setJobid] = useState("");
  const [loading, setLoading] = useState(false);
  const [benefitlist, setbenefitlist] = useState([
    "Work from home",
    "Company pension",
    "Referral programme",
    "Employee discount",
  ]);

  const [formData, setFormData] = useState({
    title: "",
    status: "Draft",
    industry: "", // Added to match jobSchema
    description: "", // Added to match jobSchema
    Key_responsibility: "", // Added to match jobSchema
    location: {
      type: "", // On-Site, Remote, etc.
      city: "",
      area: "",
      pincode: "",
      address: "",
      lat: "",
      long: "",
    },
    jobType: [], // Full-Time, Part-Time, etc.
    tools: [], // Full-Time, Part-Time, etc.
    categories: [], // Full-Time, Part-Time, etc.
    hoursPerWeek: "hours/week",
    workhours: "37.5", // Added to match jobSchema
    shift: ["Monday to Friday", "Day Shift", "Night Shift", "Overtime"], // Day shift, Night shift, etc.
    numberOfPositions: 5, // Matches numPeople from formData
    skills: [], // Programming, UI/UX Design, etc.
    educationLevel: [], // Bachelor's Degree, Master's Degree, etc.
    experienceLevel: [], // 0-1 years, 1-3 years, etc.
    employmentEligibility: [], // Minimum age of 18 years, etc.
    applicationDeadline: "", // Deadline date
    applicationMethod: "", // Online, Email, etc.
    pay: {
      showPayBy: "", // Default to "Range"
      minimum: "",
      maximum: "",
      rate: "",
      rateType: "Annually",
    },
    supplementalPay: ["Bonus scheme"], // Bonus scheme, Tips, etc.
    benefits: [], // Work from home, Health insurance, etc.
    company: {
      name: "", // Company name
      logo: "", // Company logo
      description: "", // Company description
    },
    postedDate: "", // Date of posting, could default to current date
    tags: [], // Tags for the job
    imagePath: null, // Optional field, not in jobSchema
  });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [Tools, setTools] = useState([]);
  const [certifications, setCertification] = useState([]);
  const [options, setOptions] = useState({
    experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
    educationLevel: qualifications,
    skills: jobSkills,
    categories: categories,
  });
  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      location: {
        ...prevData.location,
        [name]: value, // Update only the specific field
      },
    }));
  };
  const status = [
    { value: "Publish", label: "Published" },
    { value: "Hold", label: "Hold" },
    { value: "Disabled", label: "Disabled" },
    { value: "Closed", label: "Closed" },
    { value: "Draft", label: "Draft" },
    { value: "Block", label: "Block" },
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else if (name in formData.pay) {
      // If the name exists within the pay object, update it accordingly
      setFormData((prevData) => ({
        ...prevData,
        pay: {
          ...prevData.pay,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  useEffect(() => {
    setOptions({
      experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
    });
    const pathnameParts = location.pathname.split("/");

    // Ensure the pathname contains a job ID segment (expected to be the last part)
    const jobId = pathnameParts[pathnameParts.length - 1];
    const isValidJobId = jobId && jobId.length === 24; // Assuming the job ID length is always 24 characters

    // Additional checks if needed (e.g., validate jobId format)
    if (isValidJobId) {
      setJobid(jobId);
      setLoading(true);
      async function getJObByid() {
        try {
          const response = await api.getSingleJob(jobId);
          // response.job.status = "Publish";
          response.job.employerid = User?._id; // Assuming User is available in the scope

          if (response.status !== 500) {
            setCategories(jobCategories[response.job.industry] || []);
            setOptions({
              educationLevel: qualifications,
              categories: jobCategories[response.job.industry],
              skills: jobSkills[response.job.industry],
              experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
            });
            setLoading(false);
            setbenefitlist(response.job.benefits);
            response.job.Key_responsibility = Array.isArray(
              response.job.Key_responsibility
            )
              ? response.job.Key_responsibility.map(
                  (responsibility) =>
                    responsibility.trim().replace(/^•+\s*/, "") // Remove any existing bullets
                )
              : typeof response.job.Key_responsibility === "string"
              ? response.job.Key_responsibility.split(/[\r\n,]+/).map(
                  (responsibility) =>
                    responsibility.trim().replace(/^•+\s*/, "")
                )
              : response.job.Key_responsibility?.responsibilities ?? [];
            response.job.applicationDeadline = response.job.applicationDeadline
              ? new Date(response.job.applicationDeadline)
                  .toISOString()
                  .split("T")[0]
              : null;
            setFormData(response.job);
          } else if (response.status === 500) {
            setLoading(false);
            toastRef.current.addToast(response.message, "error");
          }
        } catch (error) {
          //console.log(error);
          toastRef.current.addToast(error.response?.data?.message, "success");
          setLoading(false);
        }
      }

      getJObByid();
    } else {
      //console.log("Invalid or missing job ID, skipping API call.");
    }
  }, [location.pathname]);

  const handleUpload = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        BASE_URL_PLATFORM + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.imagePath != "" || response.data.imagePath != "") {
        setFormData((prevData) => ({
          ...prevData,
          imagePath: response.data.imagePath,
          company: {
            ...prevData.company,
            logo: response.data.imagePath,
          },
        }));

        toastRef.current.addToast("Image uploaded successfully", "success");
        setLoading(false);
      } else {
        setLoading(false);

        toastRef.current.addToast(response?.message, "error");
      }
    } catch (error) {
      setLoading(false);
      toastRef.current.addToast(error, "error");
      console.error("Error uploading image", error);
    }
  };

  const handleCheckboxChange = (name, value) => {
    setFormData((prevData) => {
      console.log("Before change:", prevData[name]);
      let updatedData;
      if (prevData[name].includes(value)) {
        updatedData = {
          ...prevData,
          [name]: prevData[name].filter((item) => item !== value),
        };
      } else {
        updatedData = {
          ...prevData,
          [name]: [...prevData[name], value],
        };
      }
      console.log("After change:", updatedData[name]);
      return updatedData;
    });
  };
  const nextStep = (e) => {
    if (e == "4") {
      if (isFormValid()) {
        setStep((prevStep) => prevStep + 1);
      } else {
        toastRef.error("Form is incomplete. Please check the fields.", "error");
      }
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleQualificationsChange = (updatedQualifications) => {
    //console.log(updatedQualifications);

    setFormData((prevData) => ({
      ...prevData,
      educationLevel: updatedQualifications,
    }));
  };
  const handlePostJob = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!User) {
      const popup = (
        <Login
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={`/post-job/${jobId}`}
        />
      );
      openPopup(popup);
      const pathnameParts = location.pathname.split("/");

      const lastPart = pathnameParts[pathnameParts.length - 1];
      const jobid =
        lastPart && lastPart.match(/^[a-fA-F0-9]{24}$/) ? lastPart : jobId;
      if (jobid != "" || jobid != undefined) {
        const response = await api.jobupdate(jobid, formData);
        setLoading(false);
      }
      return null;
    } else {
      setLoading(true);
      const numberOfPositions = parseInt(formData.numberOfPositions, 10) || 0;
      const educationLevel = Array.isArray(formData.educationLevel)
        ? formData.educationLevel.map((qualification) => ({
            qualification: qualification.qualification,
            details: Array.isArray(qualification.details)
              ? qualification.details.map((detail) => ({
                  details: detail.details,
                  level_of_qualification: detail.level_of_qualification,
                }))
              : [],
          }))
        : [];
      const applicationMethod = Array.isArray(formData.applicationMethod)
        ? formData.applicationMethod.join(", ")
        : formData.applicationMethod;

      const payload = {
        ...formData,
        educationLevel,
        applicationMethod,
        numberOfPositions,
        Key_responsibility: formData.Key_responsibility,
        location: {
          type: formData.location.type,
          city: formData.location.city,
          area: formData.location.area,
          pincode: formData.location.pincode,
          address: formData.location.address,
          lat: formData.lat,
          long: formData.long,
        },
        company: {
          name: formData.company.name,
          logo: formData.company.logo,
          description: formData.company.description,
        },
        pay: {
          showPayBy: formData.pay.showPayBy,
          rateType: formData.pay.rateType,
          minimum: parseInt(formData.pay.minimum, 10),
          maximum: parseInt(formData.pay.maximum, 10),
          rate: parseInt(formData.pay.rate, 10),
        },
        status: formData?.status,
        employerid: !User ? "" : User?._id,
      };

      try {
        const pathnameParts = location.pathname.split("/");

        const lastPart = pathnameParts[pathnameParts.length - 1];
        const jobid =
          lastPart && lastPart.match(/^[a-fA-F0-9]{24}$/) ? lastPart : jobId;

        const response = await api.jobupdate(jobid, payload);
        setLoading(false);
        if (response.status !== 400) {
          toastRef.current.addToast("Job Updated successfully", "success");
          setTimeout(() => {
            window.location.href = "/dashboard/manage-jobs";
          }, 2000);
        } else {
          console.error("Error posting job:", response.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error posting job:", error);
      }
    }
  };
  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [isPopupOpen, setIsPopupOpen] = useState([]);

  const handleClosePopup = () => {
    //console.log();
    openPopup();
  };
  const GenerateWithAipopup = (e) => {
    e.preventDefault();
    const popup = (
      <Generateai
        onClose={() => handleClosePopup("Popup closed")}
        onSave={(data) => handleSaveGenrate(data)}
        data={formData}
        jobid={jobId}
      />
    );
    openPopup(popup);
  };
  const handleSaveGenrate = (data) => {
    setbenefitlist(data.benefits);
    //console.log(data.educationLevel.qualifications, data.educationLevel);
    setJobid(data._id);
    const responsibilities = Array.isArray(data.Key_responsibility)
      ? data.Key_responsibility
      : data.Key_responsibility?.responsibilities ||
        data.key_responsibility ||
        [];
    const formattedResponsibilities = responsibilities.length
      ? responsibilities
          .map((responsibility) => `• ${responsibility}`)
          .join("\r\n")
      : "";

    const industryExists = jobSectors.some(
      (sector) => sector.value === data.industry
    );
    if (!industryExists) {
      jobSectors.push({ value: data.industry, label: data.industry });
    }
    setFormData({
      title: data.title,
      status: data.status,
      industry: data.industry,
      categories: data.categories,
      description: data.description,
      Key_responsibility: formattedResponsibilities,
      location: {
        type: data.location.type,
        city: data.location.city,
        area: data.location.area,
        pincode: data.location.pincode,
        address: data.location.address,
        lat: data.location.lat,
        long: data.location.long,
      },
      jobType: data.jobType,
      hoursPerWeek: "hours/week",
      workhours: data.workhours,
      shift: Array.from(new Set([...formData.shift, ...data.shift])),
      numberOfPositions: data.numberOfPositions,
      skills: Array.from(new Set([...formData.skills, ...data.skills])),
      educationLevel:
        data.educationLevel.qualifications != undefined
          ? data.educationLevel.qualifications
          : data.educationLevel,
      experienceLevel: data.experienceLevel,
      employmentEligibility: data.employmentEligibility,
      applicationDeadline: data.applicationDeadline
        ? new Date(data.applicationDeadline).toISOString().split("T")[0]
        : null,
      applicationMethod: data.applicationMethod,
      pay: {
        showPayBy: data.pay.showPayBy,
        minimum: data.pay.minimum != null ? data.pay.minimum.toString() : "",
        maximum: data.pay.maximum != null ? data.pay.maximum.toString() : "",
        rate: data.pay.rate != null ? data.pay.rate.toString() : "",
        rateType: data.pay.rateType,
        hourlyRate: data.pay.hourlyRate,
        annualSalary: data.pay.annualSalary,
        monthlySalary: data.pay.monthlySalary,
        weeklyPay: data.pay.weeklyPay,
        dailyRate: data.pay.dailyRate,
        perProject: data.pay.perProject,
      },
      supplementalPay: Array.from(
        new Set([...formData.supplementalPay, ...(data.supplementalPay || [])])
      ),
      benefits: Array.from(
        new Set([...formData.benefits, ...(data.benefits || [])])
      ),
      company: {
        name: data.company.name,
        logo: data.company.logo,
        description: data.company.description,
      },
      postedDate: new Date().toISOString().split("T")[0],
      tags: data.tags,
      imagePath: null,
      _id: data._id,
    });
    setCategories(jobCategories[data.industry] || []);
    setOptions({
      educationLevel: qualifications,
      categories: jobCategories[data.industry],
      skills: jobSkills[data.industry],
      experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
    });
  };
  const handleDropdownChange = (selectedItem) => {
    setFormData({
      ...formData,
      industry: selectedItem,
    });
    setCategories(jobCategories[selectedItem]);
  };
  const [selectedValue, setSelectedValue] = useState({
    value: "hours/week",
    label: "hours/week",
  });
  const [selectedlocation, setselectedlocation] = useState({
    value: "On-Site",
    label: "On-site",
  });

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;
        const addressData = {
          address: place.formatted_address,
          city: "",
          area: "",
          pincode: "",
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        };
        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            addressData.city = component.long_name;
          }
          if (types.includes("sublocality") || types.includes("neighborhood")) {
            addressData.area = component.long_name;
          }
          if (types.includes("postal_code")) {
            addressData.pincode = component.long_name;
          }
        });
        console.log(addressData, "addressData");

        setFormData((prevFormData) => ({
          ...prevFormData,
          location: {
            ...prevFormData.location,
            ...addressData,
          },
        }));

        setInputValue(place.formatted_address);
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, []);

  const isFormValid = () => {
    // Check for required fields
    if (!formData.title || !formData.industry || !formData.description)
      return false;

    // Check if location fields are filled
    if (
      !formData.location.city ||
      !formData.location.pincode ||
      !formData.location.address
    )
      return false;

    // Check if at least one job type is selected
    if (formData.jobType.length === 0) return false;

    // Check pay fields (if necessary)
    if (formData.pay.minimum === "" || formData.pay.maximum === "")
      return false;

    // Check if `numberOfPositions` is within a max limit (example: less than 100)
    if (formData.numberOfPositions > 100) return false;

    // If all required fields are filled, return true
    return true;
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="jobinfoform animated-form" id="jobsearchform">
            <div>
              <h2 className="text-2xl font-medium">Job Information</h2>
              <div className="flex justify-end   mt-3">
                <button
                  className="px-6 py-2 rounded-full generateai"
                  onClick={(e) => GenerateWithAipopup(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    width={20}
                  >
                    <path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z" />
                  </svg>
                  Generate With Ai
                </button>
              </div>
            </div>

            <div className="p-2">
              <div className="form-group">
                <input
                  type="text"
                  name="title"
                  placeholder=""
                  defaultValue={formData.title || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
                <label className="text-sm font-medium text-gray-700">
                  Job Title
                </label>
              </div>
              <div className=" md:grid md:grid-cols-2 gap-4">
                <div className="form-group">
                  <>
                    <SearchableDropdown
                      options={jobSectors.map((sector) => ({
                        value: sector.value,
                        label: String(sector.label),
                      }))}
                      className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      multiple={false}
                      name="industry"
                      value={jobSectors.find(
                        (sector) => sector.value === formData.industry
                      )}
                      onChange={handleDropdownChange}
                    />
                    <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                      Job Industry / Sector
                    </label>
                  </>
                </div>

                <div className="relative ">
                  <Select2
                    className="categoriesdropdown"
                    id="categories"
                    name="categories"
                    multiple
                    defaultValue={formData?.categories}
                    data={categories}
                    options={{
                      placeholder: "Type Categories",
                    }}
                    onSelect={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        categories: Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        ),
                      }))
                    }
                  />
                  <label
                    htmlFor="categories"
                    className="block text-sm font-medium py-1 text-gray-700 multipleselectlabelskill"
                  >
                    Categories
                  </label>
                </div>
              </div>
              <div className="form-group col-span-2 lg:mt-0 md:mt-0 mt-6">
                <textarea
                  id="description"
                  name="description"
                  placeholder=""
                  value={formData.description || ""}
                  onChange={handleChange}
                  className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer custom-scrollbar"
                />
                <label className="form-input-label absolute top-2 left-4  peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600">
                  Description
                </label>
              </div>
              <div className="mt-3 form-group col-span-2">
                <textarea
                  id="key_responsibility"
                  name="Key_responsibility"
                  placeholder=""
                  value={
                    Array.isArray(formData.Key_responsibility)
                      ? formData.Key_responsibility.map(
                          (responsibility) => `• ${responsibility.trim()}`
                        ).join("\n")
                      : formData.Key_responsibility || ""
                  }
                  onChange={handleChange}
                  className="w-full px-4 py-2 custom-scrollbar focus:outline-none focus:border-[#FFA500] h-32 peer"
                />
                <label
                  htmlFor="key_responsibility"
                  className="form-input-label absolute top-2 left-4  peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                >
                  Key Responsibility
                </label>
              </div>
              <h1>Location</h1>
              <div className=" grid grid-cols-2 gap-4">
                <div className="form-group">
                  <>
                    <SearchableDropdown
                      options={[
                        { value: "On-Site", label: "On-site" },
                        { value: "Remote", label: "Remote" },
                        { value: "Hybrid", label: "Hybrid" },
                      ]}
                      className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                      multiple={false}
                      name="type"
                      value={selectedlocation || ""}
                      onChange={(selectedValue) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          location: {
                            ...prevData.location,
                            type: selectedValue.value,
                          },
                        }))
                      }
                      required
                    />
                    <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                      Location Type
                    </label>
                  </>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    placeholder=""
                    ref={inputRef}
                    defaultValue={formData?.location?.address || ""}
                    // onChange={handleChangeLocation}
                    className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <label className="block text-sm font-medium text-gray-700">
                    Street address
                  </label>
                </div>
              </div>
              <div className="form-group">
                <input
                  name="city"
                  type="text"
                  placeholder=""
                  defaultValue={formData?.location?.city || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
                <label className="block text-sm font-medium text-gray-700">
                  City
                </label>
              </div>
              <div className=" grid grid-cols-2 gap-4">
                <div className="form-group">
                  <input
                    type="text"
                    name="area"
                    placeholder=""
                    defaultValue={formData?.location?.area || ""}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <label className="text-sm font-medium text-gray-700">
                    Area
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="pincode"
                    placeholder=""
                    defaultValue={formData?.location?.pincode || ""}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <label className="text-sm font-medium text-gray-700">
                    Postcode
                  </label>
                </div>
              </div>
            </div>
            <div className="text-right">
              <button
                type="button"
                onClick={(e) => nextStep("1")}
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded joblist-btnclear js-btn-next"
              >
                Continue
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <>
            <h2 className="text-2xl font-medium mb-6">Job Details</h2>
            <div className="jobtypeform animated-form">
              <div className="p-2">
                <div className="jobtypeinput">
                  <div className="jobtypeinput-btn mt-2">
                    {[
                      "Full-Time",
                      "Part-Time",
                      "Permanent",
                      "Temporary contract",
                      "Freelance",
                      "Internship",
                    ].map((type) => (
                      <label
                        key={type}
                        htmlFor={type}
                        className={`jobtype-label ${
                          formData.jobType.includes(type)
                            ? "activejobtype"
                            : "notactivejobtype"
                        }`}
                      >
                        <input
                          type="checkbox"
                          name="jobType"
                          id={type}
                          value={type}
                          checked={formData.jobType.includes(type)}
                          onChange={() => handleCheckboxChange("jobType", type)}
                          className="hidden-checkbox"
                        />
                        {type}
                      </label>
                    ))}
                  </div>
                  <div className="lg:grid lg:grid-cols-3 lg:gap-2 pt-6 pl-0 pr-0 pb-0">
                    <div className="form-group flex flex-col">
                      <SearchableDropdown
                        options={[
                          { value: "", label: "Select hours/week" },
                          { value: "hours/week", label: "hours/week" },
                        ]}
                        className="block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                        multiple={false}
                        name="hoursPerWeek"
                        value={selectedValue}
                        onChange={(selectedValue) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            hoursPerWeek: selectedValue.value,
                          }))
                        }
                      />
                      <label
                        htmlFor="Showby"
                        className="block text-gray-700 SearchableDropdown"
                      >
                        Show by
                      </label>
                    </div>
                    <div className="form-group flex flex-col">
                      <input
                        type="text"
                        placeholder=""
                        id="Fixedat"
                        name="workhours"
                        defaultValue={formData?.workhours}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label htmlFor="Fixedat" className="block text-gray-700">
                        Fixed at
                      </label>
                    </div>
                    <div className="form-group flex flex-col">
                      <p
                        className="block text-gray-700"
                        style={{
                          color: "#696969",
                          fontSize: "12px",
                          marginTop: "7px",
                          padding: "0",
                        }}
                      >
                        Hours/week
                      </p>
                    </div>
                    <div className="form-group flex flex-col"></div>
                  </div>
                  <label
                    htmlFor="jobType"
                    className="block text-gray-700 font-medium mt-2 jobType"
                  >
                    Job type
                  </label>
                </div>
                <div className="jobtypeinput">
                  <div className="jobtypeinput-btn mt-2">
                    {[
                      "Monday to Friday",
                      "Day Shift",
                      "Night Shift",
                      "Overtime",
                    ].map((shiftType) => (
                      <label
                        key={shiftType}
                        className={`jobtype-label ${
                          formData.shift.includes(shiftType)
                            ? "activeshift"
                            : "notactiveshift"
                        }`}
                      >
                        <input
                          type="checkbox"
                          name="shift"
                          value={shiftType}
                          checked={formData.shift.includes(shiftType)}
                          onChange={() =>
                            handleCheckboxChange("shift", shiftType)
                          } // Move onChange here
                          className="hidden-checkbox"
                        />
                        {shiftType}
                      </label>
                    ))}
                  </div>
                  <label
                    htmlFor="shift"
                    className="block text-gray-700 font-medium mt-2 jobType"
                  >
                    Shift
                  </label>
                </div>
                <div className="relative lg:grid lg:grid-cols-2 lg:gap-2">
                  <div className="form-group">
                    <input
                      type="number"
                      id="numPeople"
                      name="numberOfPositions"
                      placeholder=""
                      defaultValue={formData.numberOfPositions}
                      onChange={handleChange}
                      className="mt-2 w-full border rounded-lg p-2"
                    />
                    <label
                      htmlFor="numPeople"
                      className="block text-gray-700 font-medium mt-2"
                    >
                      Number Of people you wish to hire?
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder=""
                      type="text"
                      id="applicationMethod"
                      name="applicationMethod"
                      defaultValue={formData.applicationMethod}
                      onChange={handleChange}
                      className="mt-2 w-full border rounded-lg p-2"
                    />
                    <label
                      htmlFor="applicationMethod"
                      className="block text-gray-700 font-medium mt-2"
                    >
                      Application Method
                    </label>
                  </div>
                </div>
                <div className="hrp-table-Preview ">
                  <QualificationsTable
                    userqualification={
                      formData?.educationLevel != ""
                        ? formData?.educationLevel
                        : []
                    }
                    onQualificationsChange={handleQualificationsChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    placeholder=""
                    type="date"
                    id="applicationDeadline"
                    name="applicationDeadline"
                    value={formData.applicationDeadline}
                    onChange={handleChange}
                    className="mt-2 w-full border rounded-lg p-2"
                    min={new Date().toISOString().split("T")[0]} // Set the minimum date to today's date
                  />
                  <label
                    htmlFor="applicationDeadline"
                    className="block text-gray-700 font-medium mt-2"
                  >
                    Application Deadline
                  </label>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-full joblist-cardbtnapply js-btn-prev"
                  onClick={prevStep}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="lg:mt-4 mt-2 bg-blue-600 text-white px-4 py-4 rounded-full joblist-btnclear js-btn-next"
                  onClick={(e) => nextStep("2")}
                >
                  Continue
                </button>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <div className="">
            <h2 className="text-2xl font-medium mb-6">Pay and Benefits</h2>
            <div className="payform animated-form">
              <div className="payReviewdiv">
                <label
                  htmlFor="payReview"
                  className="block text-gray-700 font-medium payReview"
                >
                  Pay
                </label>
                <p
                  className="text-gray-600 mb-4"
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#696969",
                  }}
                >
                  Review the pay we estimated for your job and adjust it as
                  needed. Check your local minimum wage.
                </p>

                <div className="mt-4 grid grid-cols-3 gap-4">
                  <div className="form-group flex flex-col">
                    <SearchableDropdown
                      options={[
                        { label: "Annually", value: "Annually" },
                        { label: "Monthly", value: "Monthly" },
                        { label: "Weekly", value: "Weekly" },
                        { label: "Per Day", value: "Per Day" },
                        { label: "Hourly Rate", value: "Hourly" },
                        { label: "Fixed", value: "Fixed" },
                        { label: "Range", value: "Range" },
                        { label: "Per Project", value: "Per Project" },
                      ]}
                      className="border rounded-lg p-2"
                      multiple={false}
                      name="showPayBy"
                      value={{
                        value: formData.pay.rateType,
                        label: formData.pay.rateType,
                      }}
                      onChange={(selectedItem) => {
                        if (selectedItem) {
                          setFormData((prevData) => ({
                            ...prevData,
                            pay: {
                              ...prevData.pay,
                              rateType: selectedItem,
                            },
                          }));
                        }
                      }}
                    />
                    <label
                      htmlFor="payBy"
                      className="block text-gray-700 SearchableDropdown"
                    >
                      Show pay by
                    </label>
                  </div>
                  {formData.pay.rateType === "Range" && (
                    <>
                      <div className="form-group flex flex-col">
                        <input
                          type="number"
                          id="minPay"
                          name="minimum"
                          defaultValue={formData.pay.minimum}
                          onChange={handleChange}
                          className="border rounded-lg p-2"
                        />
                        <label htmlFor="minPay" className="block text-gray-700">
                          Minimum:
                        </label>
                      </div>

                      <div className="form-group flex flex-col">
                        <input
                          type="number"
                          id="maxPay"
                          name="maximum"
                          defaultValue={formData.pay.maximum}
                          onChange={handleChange}
                          className="border rounded-lg p-2"
                        />
                        <label htmlFor="maxPay" className="block text-gray-700">
                          Maximum:
                        </label>
                      </div>
                    </>
                  )}
                  {formData.pay.rateType === "Fixed" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="fixedPay"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label htmlFor="fixedPay" className="block text-gray-700">
                        Rate:
                      </label>
                    </div>
                  )}
                  {formData.pay.rateType === "Hourly" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="hourlyRate"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="hourlyRate"
                        className="block text-gray-700"
                      >
                        Hourly Rate:
                      </label>
                    </div>
                  )}
                  {(formData.pay.rateType === "Annually" ||
                    formData.pay.rateType === "Yearly") && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="annualSalary"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="annualSalary"
                        className="block text-gray-700"
                      >
                        Annual Salary:
                      </label>
                    </div>
                  )}
                  {formData.pay.rateType === "Monthly" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="monthlySalary"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="monthlySalary"
                        className="block text-gray-700"
                      >
                        Monthly Salary:
                      </label>
                    </div>
                  )}

                  {formData.pay.rateType === "Weekly" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="weeklyPay"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="weeklyPay"
                        className="block text-gray-700"
                      >
                        Weekly Pay:
                      </label>
                    </div>
                  )}
                  {formData.pay.rateType === "Per Day" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="dailyRate"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="dailyRate"
                        className="block text-gray-700"
                      >
                        Daily Rate:
                      </label>
                    </div>
                  )}
                  {formData.pay.rateType === "Per Project" && (
                    <div className="form-group flex flex-col">
                      <input
                        type="number"
                        name="maximum"
                        id="perProject"
                        defaultValue={formData.pay.maximum}
                        onChange={handleChange}
                        className="border rounded-lg p-2"
                      />
                      <label
                        htmlFor="perProject"
                        className="block text-gray-700"
                      >
                        Per Project:
                      </label>
                    </div>
                  )}
                </div>
              </div>

              <div className="jobtypeinput">
                <div className="jobtypeinput-btn mt-2">
                  {[
                    "Bonus scheme",
                    "Yearly bonus",
                    "Performance bonus",
                    "Tips",
                  ].map((pay) => (
                    <label
                      key={pay}
                      className={`jobtype-label ${
                        formData.supplementalPay.includes(pay)
                          ? "activesupplement"
                          : "notactivesupplement"
                      }`}
                      onClick={() =>
                        setFormData((prevData) => ({
                          ...prevData,
                          supplementalPay: prevData.supplementalPay.includes(
                            pay
                          )
                            ? prevData.supplementalPay.filter(
                                (item) => item !== pay
                              )
                            : [...prevData.supplementalPay, pay],
                        }))
                      }
                    >
                      <input
                        type="checkbox"
                        name="supplementalPay"
                        value={pay}
                        checked={formData.supplementalPay.includes(pay)}
                        readOnly
                        onChange={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            supplementalPay: prevData.supplementalPay.includes(
                              pay
                            )
                              ? prevData.supplementalPay.filter(
                                  (item) => item !== pay
                                )
                              : [...prevData.supplementalPay, pay],
                          }))
                        }
                        className="hidden-checkbox"
                      />
                      {pay}
                    </label>
                  ))}
                </div>
                <label
                  htmlFor="supplementalPay"
                  className="block text-gray-700 mt-2 font-medium jobType"
                >
                  Supplemental Pay
                </label>
              </div>

              <div className="jobtypeinput">
                <div className="jobtypeinput-btn mt-2">
                  {benefitlist.map((benefit) => (
                    <label
                      key={benefit}
                      className={`jobtype-label ${
                        formData.benefits.includes(benefit)
                          ? "activebenefit"
                          : "notactivebenefit"
                      }`}
                      onClick={() =>
                        setFormData((prevData) => ({
                          ...prevData,
                          benefits: prevData.benefits.includes(benefit)
                            ? prevData.benefits.filter(
                                (item) => item !== benefit
                              )
                            : [...prevData.benefits, benefit],
                        }))
                      }
                    >
                      <input
                        type="checkbox"
                        name="benefits"
                        value={benefit}
                        checked={formData.benefits.includes(benefit)}
                        readOnly
                        onChange={() =>
                          setFormData((prevData) => ({
                            ...prevData,
                            benefits: prevData.benefits.includes(benefit)
                              ? prevData.benefits.filter(
                                  (item) => item !== benefit
                                )
                              : [...prevData.benefits, benefit],
                          }))
                        }
                        className="hidden-checkbox"
                      />
                      {benefit}
                    </label>
                  ))}
                </div>
                <label
                  htmlFor="benefits"
                  className="block mt-2 jobType text-gray-700 font-medium"
                >
                  Benefits
                </label>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="mt-4 bg-gray-500 text-white py-2 px-4 rounded joblist-cardbtnapply js-btn-prev"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={(e) => nextStep("3")}
                  className="mt-4 bg-blue-500 text-white py-2 px-4 rounded joblist-btnclear js-btn-next"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="">
            <h2 className="text-2xl font-medium mb-6">Company Information</h2>
            <div className="companyinfoform ">
              <div className="p-2 space-y-6">
                <div className="grid grid-cols-2 gap-5 items-center">
                  {/* Company Name */}
                  <div className="animated-form">
                    <div className="flex-1 form-group">
                      <input
                        type="text"
                        id="companyName"
                        name="name"
                        value={formData.company?.name || ""}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            company: {
                              ...prev.company,
                              name: e.target.value,
                            },
                          }))
                        }
                        className="mt-2 w-full border rounded-lg p-2"
                        placeholder="Company Name"
                      />
                      <label
                        htmlFor="companyName"
                        className="block text-gray-700 font-medium mt-2"
                      >
                        Company Name
                      </label>
                    </div>
                  </div>

                  {/* Company Logo */}
                  <div>
                    <div className="flex-1 flex m-auto hrp-upload-image">
                      <div
                        className="categoryEditResult"
                        style={{ cursor: "pointer" }}
                      >
                        {formData.company?.logo ? (
                          <div className="hrp-upload-image-border">
                            <img
                              className="imgse renderCategoryEdit text uploadImage-logo"
                              src={
                                formData.company?.logo &&
                                formData.company?.logo.trim() !== "" &&
                                formData.company?.logo !== "NA"
                                  ? formData.company?.logo.startsWith(
                                      "http://"
                                    ) ||
                                    formData.company?.logo.startsWith(
                                      "https://"
                                    )
                                    ? formData.company?.logo
                                    : formData.company?.logo.startsWith(
                                        "/uploads/"
                                      )
                                    ? `${BASE_URL_PLATFORM}${formData.company?.logo}`
                                    : `${BASE_URL_PLATFORM}/uploads/${formData.company?.logo}`
                                  : DefaultImage
                              }
                              alt="CompanyLogo"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = DefaultImage;
                              }}
                            />
                          </div>
                        ) : (
                          <div className="hrp-upload-image-border">
                            <img
                              src={DefaultImage}
                              className="DefaultImage-logo"
                              alt="CompanyLogo"
                            />
                          </div>
                        )}
                      </div>
                      <label
                        htmlFor="companyLogo"
                        className="hrp-upload-image-label"
                      >
                        <input
                          type="file"
                          id="companyLogo"
                          accept="image/*"
                          name="companyLogo"
                          className="w-full p-2"
                          onChange={(e) => handleUpload(e.target.files[0])}
                        />
                      </label>
                    </div>
                    <div
                      className="flex justify-center py-3 font-semibold text-sm"
                      style={{ color: "#003366" }}
                    >
                      Company Logo
                    </div>
                  </div>
                </div>

                {/* Company Description */}
                <div className="animated-form">
                  <div className="form-description">
                    <textarea
                      id="companyDescription"
                      name="description"
                      placeholder="Description"
                      value={formData.company?.description || ""}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          company: {
                            ...prev.company,
                            description: e.target.value,
                          },
                        }))
                      }
                      className="w-full px-4 py-2 focus:outline-none rounded-lg focus:border-[#FFA500] h-32 peer"
                    />
                    <label
                      htmlFor="companyDescription"
                      className="form-input-label absolute top-2 left-4 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                    >
                      Description
                    </label>
                  </div>
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="mt-4 bg-gray-500 text-white py-2 px-4 rounded joblist-cardbtnapply js-btn-prev"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={(e) => nextStep("4")}
                    className={`mt-4 bg-blue-500 text-white py-2 px-4 rounded joblist-btnclear js-btn-next ${
                      !isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={!isFormValid()}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <>
            <h2 className="text-2xl font-medium mb-6">Preview and Submit</h2>
            <div className="container mx-auto jobreview hrp-jobreview-section">
              <div className="main-JobPosting-div JobPosting-section custom-scrollbar">
                <div className="px-6 lg:flex w-full JobPosting-inner-div items-center">
                  <div className="py-10 lg:w-1/5 hrp-jobreview-section-image ">
                    <img
                      src={
                        formData.company?.logo &&
                        formData.company?.logo.trim() !== "" &&
                        formData.company?.logo !== "NA"
                          ? formData.company?.logo.startsWith("http://") ||
                            formData.company?.logo.startsWith("https://")
                            ? formData.company?.logo
                            : formData.company?.logo.startsWith("/uploads/")
                            ? `${BASE_URL_PLATFORM}${formData.company?.logo}`
                            : `${BASE_URL_PLATFORM}/uploads/${formData.company?.logo}`
                          : DefaultImage
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DefaultImage;
                      }}
                      alt="Company Logo"
                    />
                  </div>
                  <div
                    className="lg:flex items-center w-full "
                    style={{ padding: "10px 10px" }}
                  >
                    <div className="lg:w-1/2 hrp-jobreview-details-section">
                      <div>
                        <h2>{formData?.company.name}</h2>

                        <div className="location-inner">
                          <span>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.00014 14.1257L8.4809 13.584C9.02643 12.9592 9.51709 12.3665 9.95366 11.8027L10.314 11.3272C11.8188 9.2998 12.5716 7.69066 12.5716 6.50133C12.5716 3.96266 10.5251 1.90475 8.00014 1.90475C5.47519 1.90475 3.42871 3.96266 3.42871 6.50133C3.42871 7.69066 4.18147 9.2998 5.68623 11.3272L6.04662 11.8027C6.66943 12.6007 7.32103 13.375 8.00014 14.1257Z"
                                stroke="#3E3E3E"
                                strokeWidth="1.14286"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.00047 8.38093C9.05244 8.38093 9.90523 7.52814 9.90523 6.47617C9.90523 5.4242 9.05244 4.57141 8.00047 4.57141C6.94849 4.57141 6.0957 5.4242 6.0957 6.47617C6.0957 7.52814 6.94849 8.38093 8.00047 8.38093Z"
                                stroke="#3E3E3E"
                                strokeWidth="1.14286"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <h1>
                            {formData?.location.city != "" ||
                            formData?.location.city != undefined
                              ? formData?.location.city
                              : ""}
                            {formData?.location.area != "" ||
                            formData?.location.area != undefined
                              ? formData?.location.area
                              : ""}
                            ,
                            {formData?.location.address != "" ||
                            formData?.location.address != undefined
                              ? formData?.location.address
                              : ""}
                          </h1>
                        </div>
                        {formData?.pay.rateType != "" &&
                        formData?.pay.rateType != null ? (
                          <>
                            <div className="Salery-heading">
                              <h1>Salary</h1>
                            </div>
                            <div className="salery-inner">
                              <span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.9493 7.83334H12.1533C11.204 7.83334 10.4993 8.53934 10.4993 9.33334C10.4993 10.1273 11.204 10.8333 12.1527 10.8333H13.9647C14.102 10.8247 14.1613 10.732 14.166 10.676V7.99068C14.1613 7.93468 14.102 7.84201 13.9647 7.83401L13.9493 7.83334ZM13.9 6.83334C13.944 6.83334 13.9867 6.83334 14.0267 6.83601C14.6067 6.87134 15.1207 7.30934 15.164 7.92134C15.1667 7.96134 15.1667 8.00468 15.1667 8.04468V10.622C15.1667 10.662 15.1667 10.7053 15.164 10.7453C15.1207 11.3573 14.6067 11.7953 14.026 11.8313C13.9867 11.8333 13.944 11.8333 13.8993 11.8333H12.154C10.724 11.8333 9.5 10.748 9.5 9.33334C9.5 7.91868 10.724 6.83334 12.1533 6.83334H13.8887H13.9Z"
                                    fill="#4B8700"
                                  />
                                  <path
                                    d="M12.6663 9.33332C12.6663 9.51013 12.5961 9.6797 12.4711 9.80473C12.3461 9.92975 12.1765 9.99999 11.9997 9.99999C11.8229 9.99999 11.6533 9.92975 11.5283 9.80473C11.4032 9.6797 11.333 9.51013 11.333 9.33332C11.333 9.15651 11.4032 8.98694 11.5283 8.86192C11.6533 8.73689 11.8229 8.66666 11.9997 8.66666C12.1765 8.66666 12.3461 8.73689 12.4711 8.86192C12.5961 8.98694 12.6663 9.15651 12.6663 9.33332Z"
                                    fill="#4B8700"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.8997 6.83332C14.0909 6.82609 14.2813 6.86257 14.4563 6.93999C14.385 5.86932 14.1883 5.04399 13.5723 4.42732C13.073 3.92865 12.4403 3.70732 11.659 3.60199L11.631 3.59865C11.624 3.59336 11.6169 3.58825 11.6097 3.58332L9.11901 1.93199C8.68698 1.65004 8.18223 1.49991 7.66634 1.49991C7.15045 1.49991 6.64571 1.65004 6.21367 1.93199L3.72367 3.58332C3.71621 3.58824 3.70887 3.59335 3.70167 3.59865L3.67367 3.60199C2.89234 3.70732 2.25967 3.92865 1.76034 4.42732C1.26167 4.92665 1.04034 5.55932 0.935008 6.34065C0.833008 7.10065 0.833008 8.07065 0.833008 9.29599V9.37065C0.833008 10.596 0.833008 11.5667 0.935008 12.326C1.04034 13.1073 1.26167 13.74 1.76034 14.2393C2.25967 14.738 2.89234 14.9593 3.67367 15.0647C4.43367 15.1667 5.40367 15.1667 6.62901 15.1667H8.70367C9.92901 15.1667 10.8997 15.1667 11.659 15.0647C12.4403 14.9593 13.073 14.738 13.5723 14.2393C14.1883 13.6227 14.385 12.7973 14.4563 11.726C14.3217 11.786 14.1763 11.822 14.0257 11.8313C13.9863 11.8333 13.9437 11.8333 13.899 11.8333H13.445C13.369 12.728 13.1977 13.2 12.865 13.532C12.583 13.814 12.1963 13.9833 11.5257 14.0733C10.841 14.1653 9.93767 14.1667 8.66634 14.1667H6.66634C5.39501 14.1667 4.49234 14.1653 3.80634 14.0733C3.13634 13.9833 2.74967 13.814 2.46767 13.532C2.18567 13.25 2.01634 12.8633 1.92634 12.1927C1.83434 11.508 1.83301 10.6047 1.83301 9.33332C1.83301 8.06199 1.83434 7.15932 1.92634 6.47332C2.01634 5.80332 2.18567 5.41665 2.46767 5.13465C2.74967 4.85265 3.13634 4.68332 3.80701 4.59332C4.49234 4.50132 5.39501 4.49999 6.66634 4.49999H8.66634C9.93767 4.49999 10.841 4.50132 11.5263 4.59332C12.1963 4.68332 12.583 4.85265 12.865 5.13465C13.1977 5.46665 13.369 5.93932 13.445 6.83332H13.8883H13.8997ZM6.62901 3.49999H8.70367C9.04834 3.49999 9.37234 3.49999 9.67701 3.50199L8.56634 2.76532C8.03301 2.41199 7.29967 2.41199 6.76634 2.76532L5.65501 3.50199C5.96034 3.49999 6.28434 3.49999 6.62834 3.49999"
                                    fill="#4B8700"
                                  />
                                  <path
                                    d="M4 6.16666C3.86739 6.16666 3.74021 6.21934 3.64645 6.3131C3.55268 6.40687 3.5 6.53405 3.5 6.66666C3.5 6.79926 3.55268 6.92644 3.64645 7.02021C3.74021 7.11398 3.86739 7.16666 4 7.16666H6.66667C6.79927 7.16666 6.92645 7.11398 7.02022 7.02021C7.11399 6.92644 7.16667 6.79926 7.16667 6.66666C7.16667 6.53405 7.11399 6.40687 7.02022 6.3131C6.92645 6.21934 6.79927 6.16666 6.66667 6.16666H4Z"
                                    fill="#4B8700"
                                  />
                                  <path
                                    d="M12.6663 9.33332C12.6663 9.51013 12.5961 9.6797 12.4711 9.80473C12.3461 9.92975 12.1765 9.99999 11.9997 9.99999C11.8229 9.99999 11.6533 9.92975 11.5283 9.80473C11.4032 9.6797 11.333 9.51013 11.333 9.33332C11.333 9.15651 11.4032 8.98694 11.5283 8.86192C11.6533 8.73689 11.8229 8.66666 11.9997 8.66666C12.1765 8.66666 12.3461 8.73689 12.4711 8.86192C12.5961 8.98694 12.6663 9.15651 12.6663 9.33332Z"
                                    fill="#4B8700"
                                  />
                                </svg>
                              </span>
                              <h1>
                                {formData.pay.rateType === "Range" && (
                                  <>
                                    &#163;{formData.pay.minimum} - &#163;
                                    {formData.pay.maximum}
                                  </>
                                )}
                                {formData.pay.rateType === "Fixed" && (
                                  <> &#163;{formData.pay.maximum} (Fixed)</>
                                )}
                                {formData.pay.rateType === "Hourly" && (
                                  <> &#163;{formData.pay.maximum} per hour</>
                                )}
                                {formData.pay.rateType === "Annually" && (
                                  <> &#163;{formData.pay.maximum} per year</>
                                )}
                                {formData.pay.rateType === "Monthly" && (
                                  <> &#163;{formData.pay.maximum} per month</>
                                )}
                                {formData.pay.rateType === "Weekly" && (
                                  <> &#163;{formData.pay.maximum} per week</>
                                )}
                                {formData.pay.rateType === "Per Day" && (
                                  <> &#163;{formData.pay.maximum} per day</>
                                )}
                                {formData.pay.rateType === "Per Project" && (
                                  <> &#163;{formData.pay.maximum} per project</>
                                )}
                              </h1>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="lg:w-1/2 flex items-center lg:justify-center">
                      <div className="jobreviewspan">
                        {formData?.jobType && formData?.jobType.length > 0 && (
                          <>
                            <span className="block text-gray-600 text-sm font-normal">
                              Job Type
                            </span>
                            <div className="flex items-center mb-2">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.66634 14C2.29967 14 1.9859 13.8696 1.72501 13.6087C1.46412 13.3478 1.33345 13.0338 1.33301 12.6667V10H5.99967V11.3333H9.99967V10H14.6663V12.6667C14.6663 13.0333 14.5359 13.3473 14.275 13.6087C14.0141 13.87 13.7001 14.0005 13.333 14H2.66634ZM7.33301 10V8.66668H8.66634V10H7.33301ZM1.33301 8.66668V5.33334C1.33301 4.96668 1.46367 4.6529 1.72501 4.39201C1.98634 4.13112 2.30012 4.00045 2.66634 4.00001H5.33301V2.66668C5.33301 2.30001 5.46368 1.98623 5.72501 1.72534C5.98634 1.46445 6.30012 1.33379 6.66634 1.33334H9.33301C9.69967 1.33334 10.0137 1.46401 10.275 1.72534C10.5363 1.98668 10.6668 2.30045 10.6663 2.66668V4.00001H13.333C13.6997 4.00001 14.0137 4.13068 14.275 4.39201C14.5363 4.65334 14.6668 4.96712 14.6663 5.33334V8.66668H9.99967V7.33334H5.99967V8.66668H1.33301ZM6.66634 4.00001H9.33301V2.66668H6.66634V4.00001Z"
                                  fill="#3E3E3E"
                                />
                              </svg>
                              <div>
                                <span className="text-blue-600 font-medium">
                                  {formData?.jobType.join(",")}
                                </span>
                              </div>
                            </div>{" "}
                          </>
                        )}
                        {formData?.experience && (
                          <>
                            <span className="block text-gray-600 text-sm font-normal">
                              Experience
                            </span>
                            <div className="flex items-center mb-2">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.833 1.83334H3.16634C2.42996 1.83334 1.83301 2.4303 1.83301 3.16668V12.8333C1.83301 13.5697 2.42996 14.1667 3.16634 14.1667H12.833C13.5694 14.1667 14.1663 13.5697 14.1663 12.8333V3.16668C14.1663 2.4303 13.5694 1.83334 12.833 1.83334Z"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.917 6.49999C10.5413 6.49999 10.1809 6.35073 9.91526 6.08506C9.64958 5.81938 9.50033 5.45905 9.50033 5.08332C9.50033 4.7076 9.35107 4.34726 9.08539 4.08159C8.81972 3.81591 8.45938 3.66666 8.08366 3.66666H7.91699C7.54127 3.66666 7.18093 3.81591 6.91526 4.08159C6.64958 4.34726 6.50033 4.7076 6.50033 5.08332C6.50033 5.45905 6.35107 5.81938 6.08539 6.08506C5.81972 6.35073 5.45938 6.49999 5.08366 6.49999C4.70794 6.49999 4.3476 6.64925 4.08192 6.91492C3.81625 7.1806 3.66699 7.54093 3.66699 7.91666V8.08332C3.66699 8.45905 3.81625 8.81938 4.08192 9.08506C4.3476 9.35073 4.70794 9.49999 5.08366 9.49999C5.45938 9.49999 5.81972 9.64925 6.08539 9.91492C6.35107 10.1806 6.50033 10.5409 6.50033 10.9167C6.50033 11.2924 6.64958 11.6527 6.91526 11.9184C7.18093 12.1841 7.54127 12.3333 7.91699 12.3333H8.08366C8.45938 12.3333 8.81972 12.1841 9.08539 11.9184C9.35107 11.6527 9.50033 11.2924 9.50033 10.9167C9.50033 10.5409 9.64958 10.1806 9.91526 9.91492C10.1809 9.64925 10.5413 9.49999 10.917 9.49999C11.2927 9.49999 11.6531 9.35073 11.9187 9.08506C12.1844 8.81938 12.3337 8.45905 12.3337 8.08332V7.91666C12.3337 7.54093 12.1844 7.1806 11.9187 6.91492C11.6531 6.64925 11.2927 6.49999 10.917 6.49999Z"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.1231 9.00166C10.8575 8.73599 10.7082 8.37568 10.7082 7.99999C10.7082 7.6243 10.8575 7.264 11.1231 6.99833C11.3887 6.73265 11.538 6.37235 11.538 5.99666C11.538 5.62097 11.3887 5.26066 11.1231 4.99499L11.0051 4.87699C10.7394 4.61136 10.3791 4.46213 10.0034 4.46213C9.62775 4.46213 9.26745 4.61136 9.00178 4.87699C8.87022 5.00857 8.71404 5.11295 8.54214 5.18416C8.37025 5.25537 8.18601 5.29202 7.99994 5.29202C7.81388 5.29202 7.62964 5.25537 7.45775 5.18416C7.28585 5.11295 7.12966 5.00857 6.99811 4.87699C6.73244 4.61136 6.37213 4.46213 5.99644 4.46213C5.62075 4.46213 5.26045 4.61136 4.99478 4.87699L4.87678 4.99466C4.61114 5.26033 4.46191 5.62064 4.46191 5.99633C4.46191 6.37202 4.61114 6.73232 4.87678 6.99799C5.00836 7.12954 5.11273 7.28573 5.18394 7.45763C5.25516 7.62952 5.29181 7.81376 5.29181 7.99983C5.29181 8.18589 5.25516 8.37013 5.18394 8.54202C5.11273 8.71392 5.00836 8.8701 4.87678 9.00166C4.61114 9.26733 4.46191 9.62764 4.46191 10.0033C4.46191 10.379 4.61114 10.7393 4.87678 11.005L4.99444 11.123C5.26012 11.3886 5.62042 11.5379 5.99611 11.5379C6.3718 11.5379 6.73211 11.3886 6.99778 11.123C7.12933 10.9914 7.28552 10.887 7.45741 10.8158C7.62931 10.7446 7.81355 10.708 7.99961 10.708C8.18567 10.708 8.36991 10.7446 8.54181 10.8158C8.71371 10.887 8.86989 10.9914 9.00144 11.123C9.26712 11.3886 9.62742 11.5379 10.0031 11.5379C10.3788 11.5379 10.7391 11.3886 11.0048 11.123L11.1228 11.0053C11.3884 10.7397 11.5376 10.3793 11.5376 10.0037C11.5376 9.62797 11.3884 9.26766 11.1228 9.00199"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div>
                                <span className="text-blue-600 font-normal">
                                  {formData?.experience}
                                </span>
                              </div>
                            </div>{" "}
                          </>
                        )}
                        {formData?.numberOfPositions && (
                          <>
                            <span className="block text-gray-600 text-sm">
                              Number Of Positions
                            </span>
                            <div className="flex items-center">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.33301 12H8.66634V10.6667H7.33301V12ZM7.99967 1.33334C7.1242 1.33334 6.25729 1.50578 5.44845 1.84081C4.63961 2.17584 3.90469 2.66691 3.28563 3.28596C2.03539 4.53621 1.33301 6.2319 1.33301 8.00001C1.33301 9.76812 2.03539 11.4638 3.28563 12.7141C3.90469 13.3331 4.63961 13.8242 5.44845 14.1592C6.25729 14.4942 7.1242 14.6667 7.99967 14.6667C9.76778 14.6667 11.4635 13.9643 12.7137 12.7141C13.964 11.4638 14.6663 9.76812 14.6663 8.00001C14.6663 7.12453 14.4939 6.25762 14.1589 5.44879C13.8238 4.63995 13.3328 3.90502 12.7137 3.28596C12.0947 2.66691 11.3597 2.17584 10.5509 1.84081C9.74206 1.50578 8.87515 1.33334 7.99967 1.33334ZM7.99967 13.3333C5.05967 13.3333 2.66634 10.94 2.66634 8.00001C2.66634 5.06001 5.05967 2.66668 7.99967 2.66668C10.9397 2.66668 13.333 5.06001 13.333 8.00001C13.333 10.94 10.9397 13.3333 7.99967 13.3333ZM7.99967 4.00001C7.29243 4.00001 6.61415 4.28096 6.11406 4.78106C5.61396 5.28116 5.33301 5.95943 5.33301 6.66668H6.66634C6.66634 6.31305 6.80682 5.97392 7.05687 5.72387C7.30691 5.47382 7.64605 5.33334 7.99967 5.33334C8.3533 5.33334 8.69243 5.47382 8.94248 5.72387C9.19253 5.97392 9.33301 6.31305 9.33301 6.66668C9.33301 8.00001 7.33301 7.83334 7.33301 10H8.66634C8.66634 8.50001 10.6663 8.33334 10.6663 6.66668C10.6663 5.95943 10.3854 5.28116 9.88529 4.78106C9.38519 4.28096 8.70692 4.00001 7.99967 4.00001Z"
                                  fill="#4B8700"
                                />
                              </svg>
                              <div>
                                <span className="text-blue-600 font-normal">
                                  {formData?.numberOfPositions} Positions
                                </span>
                              </div>
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-3xl section-preview mx-auto px-10 py-6 bg-white shadow-md rounded-md">
                  <h1 className="section-preview-heading">{formData.title}</h1>
                  {formData?.description && (
                    <p className="mb-4">
                      <strong>Job Description:</strong> {formData?.description}
                    </p>
                  )}
                  <p className="mb-4">
                    <strong>Company:</strong> {formData?.company.name}
                  </p>
                  <p className="mb-4">
                    <strong>Company Description:</strong>{" "}
                    {formData?.company?.description}
                  </p>
                  {formData?.workhours && (
                    <p className="mb-4">
                      <strong>Hours Per Week:</strong> {formData?.workhours}
                    </p>
                  )}
                  <p className="mb-4">
                    <strong>Application DeadLine:</strong>{" "}
                    {formData?.applicationDeadline}
                  </p>
                  <p className="mb-4">
                    <strong>Key And Responsibility:</strong>
                  </p>
                  {formData?.Key_responsibility ? (
                    <ul style={{ listStyle: "inside" }} className="mb-4">
                      {typeof formData.Key_responsibility === "string" ? (
                        formData.Key_responsibility.split(/\r?\n•\s*/)
                          .filter(
                            (responsibility) => responsibility.trim() !== ""
                          )
                          .map((responsibility, index) => (
                            <li key={index}>{responsibility.trim()}</li>
                          ))
                      ) : Array.isArray(formData.Key_responsibility) ? (
                        formData.Key_responsibility.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                      ) : (
                        <li>No valid responsibilities found.</li>
                      )}
                    </ul>
                  ) : (
                    "No responsibilities listed."
                  )}
                  <p className="mb-4">
                    <strong>Eligibility:</strong>
                  </p>
                  <div className="hrp-table-Preview">
                    <table style={styles.table}>
                      <thead>
                        <tr style={styles.rowstyle}>
                          <th style={styles.header}>Category</th>
                          <th style={styles.header}>Details</th>
                          <th style={styles.header}>Level of Qualification</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData?.educationLevel?.map(
                          (qualification, qIndex) => (
                            <React.Fragment key={qIndex}>
                              {qualification?.details.map((detail, dIndex) => (
                                <tr key={dIndex} style={styles.rowstyle}>
                                  {dIndex === 0 && (
                                    <td
                                      rowSpan={qualification.details.length}
                                      style={styles.categoryCell}
                                    >
                                      <div style={styles.text}>
                                        <p style={styles.categoryText}>
                                          {qualification.qualification}
                                        </p>
                                      </div>
                                    </td>
                                  )}
                                  <td style={styles.cell}>
                                    <div style={styles.text2}>
                                      {detail.details}
                                    </div>
                                  </td>
                                  <td style={styles.crossWithTd}>
                                    <div style={styles.text2}>
                                      {detail.level_of_qualification}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>{" "}
                  {formData?.jobType && formData?.jobType.length > 0 && (
                    <p className="mb-4">
                      <strong>Job Type:</strong>{" "}
                      {formData?.jobType?.join(", ") || ""}
                    </p>
                  )}
                  <p className="mb-4">
                    <strong>Pay:</strong>
                    {formData.pay.rateType === "Range" && (
                      <>
                        &#163;{formData.pay.minimum} - &#163;
                        {formData.pay.maximum}
                      </>
                    )}
                    {formData.pay.rateType === "Fixed" && (
                      <> &#163;{formData.pay.maximum} (Fixed)</>
                    )}
                    {formData.pay.rateType === "Hourly" && (
                      <> &#163;{formData.pay.maximum} per hour</>
                    )}
                    {formData.pay.rateType === "Annually" && (
                      <> &#163;{formData.pay.maximum} per year</>
                    )}
                    {formData.pay.rateType === "Monthly" && (
                      <> &#163;{formData.pay.maximum} per month</>
                    )}
                    {formData.pay.rateType === "Weekly" && (
                      <> &#163;{formData.pay.maximum} per week</>
                    )}
                    {formData.pay.rateType === "Per Day" && (
                      <> &#163;{formData.pay.maximum} per day</>
                    )}
                    {formData.pay.rateType === "Per Project" && (
                      <> &#163;{formData.pay.maximum} per project</>
                    )}
                  </p>
                  {formData?.skills && formData?.skills.length > 0 && (
                    <>
                      <p className="mb-4">
                        <strong>Skills:</strong>
                      </p>
                      <ul className="list-disc list-inside mb-4">
                        {formData?.skills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>{" "}
                    </>
                  )}
                  {formData?.benefits && formData?.benefits.length > 0 && (
                    <>
                      <p className="mb-4">
                        <strong>Benefits:</strong>
                      </p>
                      <ul className="list-disc list-inside mb-4">
                        {formData?.benefits.map((benefit, index) => (
                          <li key={index}>{benefit}</li>
                        ))}
                      </ul>{" "}
                    </>
                  )}
                  {formData?.shift && formData?.shift.length > 0 && (
                    <p className="mb-4">
                      <strong>Schedule:</strong>{" "}
                      {formData?.shift?.join(", ") || ""}
                    </p>
                  )}
                  {formData?.supplementalPay &&
                    formData?.supplementalPay.length > 0 && (
                      <p className="mb-4">
                        <strong>Supplemental pay types:</strong>{" "}
                        {formData?.supplementalPay?.join(", ") || ""}
                      </p>
                    )}
                </div>
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="mt-4 bg-gray-500 text-white py-2 px-4 rounded joblist-cardbtnapply js-btn-prev"
                >
                  Back
                </button>
                <div className="flex justify-between">
                  <div className="relative job-search-label-section singleselect2 statusenroll">
                    <Select2
                      data={status.map((type) => ({
                        id: type.value,
                        text: type.label,
                        title:
                          type.value === "Block"
                            ? "Contact with superadmin"
                            : "", // Tooltip for Block status
                      }))}
                      className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                      defaultValue={formData.status}
                      onSelect={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          status: e.target.value, // Directly set the selected value for a single select
                        }))
                      }
                      options={{
                        placeholder: "Select Status",
                        width: "100%",
                        theme: "classic",
                      }}
                      disabled={formData.status === "Block"} // Disable Select2 if status is Block
                      optionRenderer={(option) => (
                        <div title={option.title || ""}>{option.text}</div> // Use title for tooltip
                      )}
                    />
                  </div>
                  <button
                    type="button"
                    className="mt-4 bg-green-500 text-white py-2 px-4 rounded joblist-btnclear"
                    onClick={handlePostJob}
                  >
                    {jobId && jobId !== "" ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  const steps = [
    { title: "Job Information", icon: "job" },
    { title: "Job Details", icon: "details" },
    { title: "Pay and Benefits", icon: "pay" },
    { title: "Company Information", icon: "company" },
    { title: "Preview and Submit", icon: "submit" },
  ];

  const renderIcon = (icon, isActive) => {
    const icons = {
      job: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFA500"
            d="M10.2083 26.2503H13.125V23.3337H16.0417V20.417H13.125V17.5003H10.2083V20.417H7.29166V23.3337H10.2083V26.2503ZM18.9583 21.1462H27.7083V18.9587H18.9583V21.1462ZM18.9583 25.5212H24.7917V23.3337H18.9583V25.5212ZM5.83332 32.0837C5.03124 32.0837 4.34485 31.7983 3.77416 31.2276C3.20346 30.6569 2.91763 29.97 2.91666 29.167V13.1253C2.91666 12.3232 3.20249 11.6369 3.77416 11.0662C4.34582 10.4955 5.03221 10.2096 5.83332 10.2087H13.125V5.83366C13.125 5.03158 13.4108 4.34519 13.9825 3.77449C14.5542 3.2038 15.2405 2.91796 16.0417 2.91699H18.9583C19.7604 2.91699 20.4473 3.20283 21.0189 3.77449C21.5906 4.34616 21.876 5.03255 21.875 5.83366V10.2087H29.1667C29.9687 10.2087 30.6556 10.4945 31.2273 11.0662C31.7989 11.6378 32.0843 12.3242 32.0833 13.1253V29.167C32.0833 29.9691 31.798 30.656 31.2273 31.2276C30.6566 31.7993 29.9697 32.0846 29.1667 32.0837H5.83332ZM5.83332 29.167H29.1667V13.1253H21.875C21.875 13.9274 21.5896 14.6143 21.0189 15.186C20.4483 15.7576 19.7614 16.043 18.9583 16.042H16.0417C15.2396 16.042 14.5532 15.7566 13.9825 15.186C13.4118 14.6153 13.126 13.9284 13.125 13.1253H5.83332V29.167ZM16.0417 13.1253H18.9583V5.83366H16.0417V13.1253Z"
          />
        </svg>
      ),
      details: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFA500"
            d="M16.0417 13.1247C16.0417 15.5455 14.0876 17.4997 11.6667 17.4997C9.24591 17.4997 7.29175 15.5455 7.29175 13.1247C7.29175 10.7038 9.24591 8.74967 11.6667 8.74967C14.0876 8.74967 16.0417 10.7038 16.0417 13.1247ZM20.4167 29.1663H2.91675V26.2497C2.91675 23.0268 6.83966 20.4163 11.6667 20.4163C16.4938 20.4163 20.4167 23.0268 20.4167 26.2497M10.2084 13.1247C10.2084 13.9268 10.8647 14.583 11.6667 14.583C12.4688 14.583 13.1251 13.9268 13.1251 13.1247C13.1251 12.3226 12.4688 11.6663 11.6667 11.6663C10.8647 11.6663 10.2084 12.3226 10.2084 13.1247ZM5.83341 26.2497H17.5001C17.5001 24.6455 14.8897 23.333 11.6667 23.333C8.44383 23.333 5.83341 24.6455 5.83341 26.2497ZM32.0834 17.4997V20.4163H18.9584V17.4997M32.0834 11.6663V14.583H18.9584V11.6663M32.0834 5.83301V8.74967H18.9584V5.83301H32.0834Z"
          />
        </svg>
      ),
      pay: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFA500"
            d="M25.5207 24.5288L29.079 26.5851L27.9853 28.4809L23.3332 25.7976V20.4163H25.5207V24.5288ZM34.9998 24.7913C34.9998 30.4351 30.4353 34.9997 24.7915 34.9997C19.1478 34.9997 14.5832 30.4351 14.5832 24.7913C14.5832 24.2955 14.6269 23.8143 14.6998 23.333H2.9165V5.83301H29.1665V15.5747C32.6082 17.2226 34.9998 20.7226 34.9998 24.7913ZM15.5748 20.4163C15.8373 19.8913 16.1144 19.3955 16.4498 18.9143C16.3186 18.958 16.1873 18.958 16.0415 18.958C13.6207 18.958 11.6665 17.0038 11.6665 14.583C11.6665 12.1622 13.6207 10.208 16.0415 10.208C18.4623 10.208 20.4165 12.1622 20.4165 14.583C20.4165 14.9476 20.3582 15.3122 20.2707 15.6476C21.6415 14.9768 23.1728 14.583 24.7915 14.583C25.2873 14.583 25.7686 14.6268 26.2498 14.6997V11.6663C25.4763 11.6663 24.7344 11.3591 24.1874 10.8121C23.6405 10.2651 23.3332 9.52322 23.3332 8.74967H8.74984C8.74984 10.3684 7.45192 11.6663 5.83317 11.6663V17.4997C6.60672 17.4997 7.34858 17.807 7.89557 18.3539C8.44255 18.9009 8.74984 19.6428 8.74984 20.4163H15.5748ZM32.0832 24.7913C32.0832 20.7663 28.8165 17.4997 24.7915 17.4997C20.7665 17.4997 17.4998 20.7663 17.4998 24.7913C17.4998 28.8163 20.7665 32.083 24.7915 32.083C28.8165 32.083 32.0832 28.8163 32.0832 24.7913Z"
          />
        </svg>
      ),
      company: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFA500"
            d="M26.2498 21.875H23.3332V24.7917H26.2498M26.2498 16.0417H23.3332V18.9583H26.2498M29.1665 27.7083H17.4998V24.7917H20.4165V21.875H17.4998V18.9583H20.4165V16.0417H17.4998V13.125H29.1665M14.5832 10.2083H11.6665V7.29167H14.5832M14.5832 16.0417H11.6665V13.125H14.5832M14.5832 21.875H11.6665V18.9583H14.5832M14.5832 27.7083H11.6665V24.7917H14.5832M8.74984 10.2083H5.83317V7.29167H8.74984M8.74984 16.0417H5.83317V13.125H8.74984M8.74984 21.875H5.83317V18.9583H8.74984M8.74984 27.7083H5.83317V24.7917H8.74984M17.4998 10.2083V4.375H2.9165V30.625H32.0832V10.2083H17.4998Z"
          />
        </svg>
      ),
      submit: (
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FFA500"
            d="M15.6189 5.85673L4.96573 26.9734C4.06886 28.7467 5.62198 30.8001 7.43761 30.2401L16.9314 27.3467C17.3251 27.2301 17.7189 27.2301 18.1126 27.3467L27.6064 30.2401C29.422 30.8001 30.9751 28.7467 30.0782 26.9734L19.4251 5.85673C19.2406 5.49648 18.9685 5.19586 18.6375 4.98643C18.3065 4.777 17.9287 4.66648 17.5439 4.66648C17.159 4.66648 16.7812 4.777 16.4502 4.98643C16.1192 5.19586 15.8471 5.49648 15.6626 5.85673L15.6189 5.85673Z"
          />
        </svg>
      ),
    };

    return icons[icon];
  };

  return (
    <div className="container m-auto hrp-job-post-page jobpostpage multisteps-form">
      <div className="flex flex-col md:flex-row max-w-screen-xl mx-auto p-3 justify-evenly">
        <div className="w-full md:w-4/5 p-4 hrp-job-post-section-1">
          <form id="jobpost">{renderStep()}</form>
        </div>
        <div className="w-full md:w-2/5 p-4 hrp-job-post-section-2">
          <div className="">
            <div>
              <img
                className="w-80 mx-auto h-30"
                src={bg_upload_CV}
                alt="Upload CV"
              />
            </div>
            <div className="bg-white border rounded-lg p-4 mt-9 multisteps-form__progress">
              <div className="space-y-4">
                {steps.map((stepItem, index) => (
                  <div
                    key={index}
                    className="flex items-center"
                    style={{ padding: "20px" }}
                  >
                    <div className="relative flex items-center justify-center">
                      <div
                        className={`flex items-center justify-center w-10 h-10  ${
                          step > index
                            ? "activestep border-orange-500 bg-orange-500 multisteps-form__progress-btn"
                            : "notactivestep multisteps-form__progress-btn"
                        }`}
                      >
                        <span>
                          {renderIcon(
                            stepItem.icon,
                            step === index + 1,
                            step > index
                          )}
                        </span>
                      </div>
                      {index !== steps.length - 1 && (
                        <div
                          className={`absolute left-1/2 transform -translate-x-1/2 w-0.5 h-12 bg-gray-300 ${
                            step > index ? "bg-orange-500" : ""
                          }`}
                          style={{ top: "108%" }}
                        ></div>
                      )}
                    </div>
                    <div className="ml-4">
                      <h3
                        className={`font-medium ${
                          step === index + 1 ? "orangeh3" : "notorangeh3"
                        }`}
                      >
                        {stepItem.title}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
      <VisitorTracker />
    </div>
  );
};

export default JobForm;
