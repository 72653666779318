import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { usePopup } from "../Common/PopupContext";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import { getUserData } from "../../helpers/utils";
import CancelInterview from "../popup/CancelInterview";
// Assuming you have a Loader component

const domainpath = process.env.REACT_APP_DOMAIN;

const InterViewSchedule = () => {
  const User = getUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const toastRef = useRef();
  const { openPopup } = usePopup();
  useEffect(() => {
    const userid = User._id;
    const status = "Interview Scheduled";

    async function getJobs() {
      try {
        setLoading(true);
        const response = await api.getapplications(userid, status);
        if (response.status !== 400) {
          setData(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getJobs();
  }, []);
  const handleClosePopup = (message) => {
    //setPopupComponent(null);
    openPopup();
  };
  // Function to open the cancel popup
  const openCancelPopup = (applicationId) => {
    // setSelectedJob(job);
    const popup = (
      <CancelInterview
        // onSave={(formData) => handleProfileform(formData)}
        onClose={() => handleClosePopup("Popup closed")}
        applicationId={applicationId}
      />
    );
    //setPopupComponent(popup);
    openPopup(popup);
    // setCancelPopup(true);
  };

  const handleConfirmInterview = async (e, applicationId) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await api.confirmInterview(applicationId); // Add any additional data as needed
      if (res.status === 200) {
        toastRef.current.addToast(
          "Interview confirmed successfully!",
          "success"
        );
        // Optionally reload or redirect after confirming
        window.location.reload();
      }
    } catch (err) {
      toastRef.current.addToast(
        err.response?.data?.message ||
          "An error occurred while confirming the interview.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Job Title & Company",
      selector: (row) => (
        <div>
          <div>
            <a
              href={`${domainpath}/jobs/${row?.jobId?._id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.jobId?.title || "N/A"}
            </a>
          </div>
          <div style={{ fontSize: "12px", color: "#555" }}>
            {row.jobId?.company.name || "Unknown Company"}
          </div>
        </div>
      ),
      sortable: true,
      width: "30%",
    },
    {
      name: "Interview Date",
      selector: (row) =>
        new Date(row.interviewDetails?.date).toLocaleDateString() || "Not Set",
      sortable: true,
      width: "20%",
    },
    {
      name: "Interview Time",
      selector: (row) => row.interviewDetails?.time || "Not Set",
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="lg:flex gap-1 xl:py-0 py-2 m-auto">
          <button
            className="manage-edit-btn text-white xl:px-6 lg:px-5 px-9 md:px-9 py-2 bg-green-500"
            onClick={(e) => handleConfirmInterview(e, row?._id)}
          >
            Confirm
          </button>
          <button
            className="manage-edit-btn text-white xl:px-2 px-6 lg:px-4 py-2 lg:mt-0 md:mt-1 mt-2 xl:mt-0 bg-red-500"
            onClick={() => openCancelPopup(row?._id)}
          >
            Cancel
          </button>
        </div>
      ),
      width: "30%",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="p-8">
      {/* Header Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <h1 className="text-2xl font-semibold" style={{ color: "#003366" }}>
          Your Scheduled Interviews
        </h1>
      </div>
      <div className="bg-white p-4 rounded-lg shadow">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}{" "}
      <CustomToastContainer ref={toastRef} />
      {/* Cancel Popup */}
      {/* {cancelPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Cancel Interview</h2>
            <label>Reason for Cancellation:</label>
            <textarea
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              rows={4}
              placeholder="Please provide a reason for cancellation."
            />
            <button onClick={handleCancelInterview}>Confirm Cancel</button>
            <button onClick={() => setCancelPopup(false)}>Close</button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default InterViewSchedule;
