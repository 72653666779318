import React, { useState, useRef } from "react";
import { getUserData } from "../../helpers/utils";
import axios from "axios";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import FeatherIcon from "feather-icons-react";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const SiteSettings = () => {
  const User = getUserData();
  const isSuperAdmin = User?.role === "superadmin"; // Check for superadmin role

  const initialState = {
    email: User?.email || "",
    password: "",
    confirmPassword: "",
    facebook_accesstoken: User?.facebook_accesstoken || "",
    profileimg: User?.profileimg || null,
    status: User?.status === "active" ? true : false,
  };
  console.log(initialState,"user",User);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toastRef = useRef();
  const [email, setEmail] = useState(initialState.email);
  const [FacebookAccess, setFacebookAccess] = useState(
    initialState.facebook_accesstoken
  );
  const [password, setPassword] = useState(initialState.password);
  const [confirmPassword, setConfirmPassword] = useState(
    initialState.confirmPassword
  );
  const [profileimg, setProfileimg] = useState(initialState.profileimg);
  const [status, setStatus] = useState(initialState.status); // New state for status

  const handleUpload = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        `${domainpath}/api/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.imagePath) {
        setProfileimg(response.data.imagePath);
        setLoading(false);

        toastRef.current.addToast("Image uploaded successfully", "success");
      } else {
        setLoading(false);

        toastRef.current.addToast(response?.message, "error");
      }
    } catch (error) {
      toastRef.current.addToast("Error uploading image", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      email: email,
      facebook_accesstoken: FacebookAccess,
      password: password,
      profileimg: profileimg,
      status: status === true ? "active" : "inactive", // Add status to form data
    };
    try {
      const response = await api.UpdateUser(User?._id, formData);
      if (response.status === 200) {
        toastRef.current.addToast("Account Updated successfully", "success");
        setLoading(false);
      } else {
        console.error("Error updating user:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };

  const handleStatusToggle = () => {
    setStatus((prevStatus) => !prevStatus); // Toggle the boolean value
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="p-8 account-settings-main">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <h1 className="account-settings">Account Settings</h1>
            {/* Toggle Switch for Status */}
            <div className="toggle-container">
              <span className="status-label">Status:</span>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  id="status-toggle"
                  className="toggle-input"
                  checked={status} // Checked when active
                  onChange={handleStatusToggle}
                />
                <div className="toggle-bg">
                  <span className="toggle-knob"></span>
                </div>
              </label>
              <span className="status-text">
                {status === true ? "Active" : "Inactive"}
              </span>
            </div>
          </div>

          <form id="updateaccount">
            <div className="relative lg:w-52 lg:h-40 hrp-profile-header-image">
              <label
                htmlFor="profileimg"
                className="absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
              >
                <input
                  type="file"
                  id="profileimg"
                  accept="image/*"
                  name="profileimg"
                  onChange={(e) => handleUpload(e.target.files[0])}
                  className="hidden"
                />
                <div className="w-full bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image relative group">
                  <img
                    alt="Profile"
                    className="w-full h-full rounded-full"
                    src={
                      profileimg
                        ? profileimg.startsWith("http")
                          ? profileimg
                          : `${domainpath}/uploads/${profileimg}`
                        : domainpath + "/uploads/deafultman.png"
                    }
                    style={{ width: " 120px" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = domainpath + "/uploads/deafultman.png";
                    }}
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full">
                    <span className="text-white text-sm font-semibold text-center">
                      Change Profile
                    </span>
                  </div>
                </div>
              </label>
            </div>

            {/* Form Grid */}
            <div className="grid grid-cols-2 gap-6">
              {/* Email Field */}
              <div
                className={
                  isSuperAdmin
                    ? "animated-form profileheadlinediv"
                    : "animated-form profileheadlinediv col-span-2"
                }
              >
                <div className="form-group">
                  <input
                    id="profileheadline"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border rounded w-full py-2 px-3 text-gray-700 "
                    type="text"
                    // readOnly={!isSuperAdmin} // Make it readonly unless superadmin
                  />
                  <label htmlFor="profileheadline" className="profileheadline">
                    Email
                  </label>
                </div>{" "}
              </div>
              {isSuperAdmin && (
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <input
                      id="facebook_accesstoken"
                      name="facebook_accesstoken"
                      defaultValue={FacebookAccess}
                      onChange={(e) => setFacebookAccess(e.target.value)}
                      className="border rounded w-full py-2 px-3 text-gray-700 "
                      type="text"
                    />
                    <label
                      htmlFor="facebook_accesstoken"
                      className="profileheadline"
                    >
                      Facebook Access Token
                    </label>
                  </div>
                </div>
              )}
              {/* Conditionally Render Facebook Access Token */}

              {/* Password Fields */}
              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <input
                    id="password"
                    className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="password-label">
                    Password
                  </label>
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? (
                      <FeatherIcon
                        icon="eye-off"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>

              <div className="animated-form profileheadlinediv">
                <div className="form-group">
                  <input
                    id="confirmpassword"
                    name="confirmpassword"
                    className="border rounded w-full py-2 px-3 text-gray-700 "
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <label htmlFor="confirmpassword" className="profileheadline">
                    Confirm Password
                  </label>
                </div>
              </div>
            </div>

            {error && <p className="text-red-500 text-xs italic">{error}</p>}

            <div className="flex gap-2 mt-4">
              <button
                className="ViewDetails-Cancel text-white px-4 py-2"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
              <button
                className="ViewDetails-btn text-white px-4 py-2"
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>

        {loading && (
          <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
        <CustomToastContainer ref={toastRef} />
      </div>
    </>
  );
};

export default SiteSettings;
