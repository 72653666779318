import React, { useState, useRef } from "react";
import api from "../../Api.js";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
import DefaultImage from "../../image/upload-course-Thumbnail.jpg";

import axios from "axios";
import { getUserData } from "../../helpers/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SearchableDropdown from "../../helpers/SearchableDropdown";
import {
  jobSectors,
  jobCategories,
  jobSkills,
  jobIndustryTools,
  qualifications,
  jobcertifications,
  toolswithicon,
  courseLevels,
  courseDurations,
  languages,
} from "../../helpers/Object";
const BASE_URL_PLATFORM = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }], // Font and size dropdowns
    ["bold", "italic", "underline", "strike"], // Bold, italic, underline, strikethrough
    [{ script: "sub" }, { script: "super" }], // Subscript, superscript
    [{ align: [] }, { list: "ordered" }, { list: "bullet" }], // Align, ordered list, bullet list
    ["blockquote", "code-block"], // Blockquote, code block
    [{ indent: "-1" }, { indent: "+1" }], // Indent/outdent
    [{ direction: "rtl" }], // Text direction (right to left)
    ["link", "image", "video"], // Insert link, image, video
    ["clean"], // Clear formatting
    [{ table: [] }], // Insert table (table module is separate)
  ],
};

const MultiStepCourseForm = () => {
  const toastRef = useRef();
  const User = getUserData();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    courseTitle: "",
    skills: [],
    courseDescription: "",
    categories: [],
    industry: "",
    category: "",
    subcategory: "",
    courseLevel: "",
    courseDuration: "",
    language: "",
    languages: [],
    price: "",
    discountPrice: "",
    courseThumbnail: null,
    courseVideoPreview: null,
    instructorName: "",
    instructorBio: "",
    courseObjectives: "",
    prerequisites: "",
    courseCurriculum: [],
    certification: false,
    targetAudience: "",
    enrollmentLimit: "",
    releaseDate: "",
    courseResources: [],
    liveSessions: false,
    supportInformation: "",
    seoTitle: "",
    seoDescription: "",
    coursetype: [],
    workshop: "",
    jobType: [],
  });
  const [categories, setCategories] = useState([]);
  const [Tools, setTools] = useState([]);
  const [certifications, setCertification] = useState([]);
  const [instructorError, setInstructorError] = useState(false);
  const [isOfqualAccredited, setIsOfqualAccredited] = useState(false);
  const [skills, setSkills] = useState([]);
  const [options, setOptions] = useState({
    experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
    educationLevel: qualifications,
    skills: jobSkills,
    categories: categories,
  });
  const handleIndustryChange = (selectedOptions) => {
    //console.log(e.target.value);
    const industry = selectedOptions;
    setFormData((prevFormData) => ({
      ...prevFormData,
      industry: industry,
    }));
    if (industry) {
      setOptions({
        educationLevel: qualifications,
        categories: jobCategories[industry],
        skills: jobSkills[industry],
        experience: ["0-1 years", "1-3 years", "3-5 years", "5+ years"],
      });
      setSkills(jobSkills[industry]);
      setCertification(jobcertifications[industry] || []);
      setCategories(jobCategories[industry] || []);
      setTools(jobIndustryTools[industry]);
    } else {
      setOptions({
        educationLevel: [],
        skills: [],
      });
      setSkills([]);
    }
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // const courseLevels = [
  //   { id: 1, text: "Level E3" },
  //   { id: 2, text: "Level E2" },
  //   { id: 3, text: "Level E1" },
  //   { id: 4, text: "Level 1" },
  //   { id: 5, text: "Level 2" },
  //   { id: 6, text: "Level 3" },
  //   { id: 7, text: "Level 4" },
  //   { id: 8, text: "Level 5" },
  //   { id: 9, text: "Level 6" },
  //   { id: 10, text: "Level 7" },
  // ];
  const handleCheckboxChange = (name, value) => {
    setFormData((prevData) => {
      if (prevData[name].includes(value)) {
        return {
          ...prevData,
          [name]: prevData[name].filter((item) => item !== value),
        };
      } else {
        return {
          ...prevData,
          [name]: [...prevData[name], value],
        };
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]:
        type === "checkbox"
          ? checked
          : value || (name === "releaseDate" && !value ? null : value),
    });

    // Set instructor error if the field is empty
    if (name === "instructorName") {
      setInstructorError(!value);
    }
  };
  const [courseDetails, setCourseDetails] = useState(null);
  const handleCourseTitle = (title) => {
    async function fetchCourseDetails() {
      try {
        const details = await api.getCourseDeatils(title);
        //console.log(details);

        setCourseDetails(details);
        setFormData({
          ...formData,
          courseTitle: title,
          courseDescription: Array.isArray(details.courseDescription)
            ? details.courseDescription.join("\r\n")
            : "", // Ensure it's a string

          courseObjectives: Array.isArray(details.courseObjectives)
            ? details.courseObjectives.join("\r\n")
            : "", // Ensure it's a string

          prerequisites: Array.isArray(details.prerequisites)
            ? details.prerequisites.join("\r\n")
            : "",

          targetAudience: Array.isArray(details.targetAudience)
            ? details.targetAudience.join("\r\n")
            : "",

          courseResources: Array.isArray(details.courseResources)
            ? details.courseResources
            : "",

          industry: details.industry || "",
          category: Array.isArray(details.category)
            ? details.category.join(", ")
            : "",

          skills: Array.isArray(details.skills)
            ? details.skills.join(", ")
            : "",
        });
        setCategories(jobCategories[details.industry]);
        setSkills(jobSkills[details.industry]);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch course details", error);
        setLoading(false);
      }
    }
    fetchCourseDetails();
  };

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        BASE_URL_PLATFORM + "/api/upload/image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevData) => ({
        ...prevData,
        courseThumbnail: response.data.imagePath,
      }));
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };

  const handleVideoUpload = async (selectedVideo) => {
    const formData = new FormData();
    formData.append("video", selectedVideo);

    try {
      const response = await axios.post(
        BASE_URL_PLATFORM + "/api/upload/video",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevData) => ({
        ...prevData,
        courseVideoPreview: response.data.videoPath,
      }));
    } catch (error) {
      console.error("Error uploading video", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.seller = User?._id;
    //console.log(formData);

    try {
      const response = await api.addCousre(formData);
      if (response.status !== 500) {
        toastRef.current.addToast("Course Created Successffully", "success");
        setLoading(false);
        window.location.href = "/dashboard/courses";
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      // alert("Failed to submit course. Please try again.");
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };

  const [sections, setSections] = useState([
    { title: "", lectures: [{ title: "", videoLink: "", duration: "" }] },
  ]);

  const handleAddSection = () => {
    setSections([
      ...sections,
      { title: "", lectures: [{ title: "", videoLink: "", duration: "" }] },
    ]);
  };

  const handleRemoveSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };

  const handleSectionChange = (index, e) => {
    const updatedSections = [...sections];
    updatedSections[index][e.target.name] = e.target.value;
    setSections(updatedSections);
  };

  const handleAddLecture = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lectures.push({
      title: "",
      videoLink: "",
      duration: "",
    });
    setSections(updatedSections);
  };

  const handleRemoveLecture = (sectionIndex, lectureIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lectures.splice(lectureIndex, 1);
    setSections(updatedSections);
  };

  const handleLectureChange = (sectionIndex, lectureIndex, e) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lectures[lectureIndex][e.target.name] =
      e.target.value;
    setSections(updatedSections);
  };
  const courseTypes = [
    { id: "live-online", text: "Live Online" },
    { id: "self-paced", text: "Self-Paced" },
    { id: "instructor-led", text: "Instructor-Led" },
    { id: "blended", text: "Blended" },
    { id: "workshops", text: "Workshops" },
    { id: "webinars", text: "Webinars" },
    { id: "certification", text: "Certification" },
    { id: "degree-programs", text: "Degree Programs" },
    { id: "micro-credentials", text: "Micro-Credentials" },
    { id: "corporate-training", text: "Corporate Training" },
  ];
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
  const handleVideoLinkChange = (e) => {
    const videoLink = e.target.value;
    let videoPreview = "";

    if (youtubeRegex.test(videoLink)) {
      const youtubeMatch = youtubeRegex.exec(videoLink);
      videoPreview = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (vimeoRegex.test(videoLink)) {
      const vimeoMatch = vimeoRegex.exec(videoLink);
      videoPreview = `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }

    setFormData((prevData) => ({
      ...prevData,
      courseVideoPreview: videoPreview,
    }));
  };
  const handleQuillChange = (value, fieldName) => {
    // Check if courseResources field is being updated
    if (fieldName === "courseResources") {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value.split("\n"),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };
  console.log(formData, "formData");

  return (
    <div className="max-w-6xl mx-auto p-6 add-course-page headlinediv">
      <div className="flex">
        <div className="w-4/6 md:pl-4">
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <div>
                <h2 className="text-2xl font-bold">Course Information</h2>

                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <input
                      type="text"
                      name="courseTitle"
                      placeholder=""
                      value={formData.courseTitle}
                      onChange={(e) => {
                        handleChange(e);
                        handleCourseTitle(e.target.value);
                      }}
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="courseTitle"
                    />
                    <label htmlFor="courseTitle" className="profileheadline">
                      Title
                    </label>
                  </div>
                </div>

                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={formData.courseDescription || ""}
                      onChange={(e) =>
                        handleQuillChange(e, "courseDescription")
                      }
                      className="border rounded w-full text-gray-700"
                      id="courseDescription"
                    />
                    <label
                      htmlFor="courseDescription"
                      className="profileheadline description-label"
                    >
                      Course Description
                    </label>
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-5">
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <SearchableDropdown
                        options={jobSectors.map((sector) => ({
                          value: sector.value,
                          label: sector.label,
                        }))}
                        className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                        multiple={false}
                        name="industry"
                        value={jobSectors.find(
                          (sector) => sector.value === formData.industry
                        )}
                        onChange={handleIndustryChange}
                      />
                      <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                        Job Industry / Sector
                      </label>
                    </div>
                  </div>
                  <div className="animated-form profileheadlinediv">
                    <div className="relative ">
                      <Select2
                        className="categoriesdropdown"
                        id="categories"
                        name="categories"
                        multiple
                        defaultValue={formData?.categories}
                        data={categories}
                        options={{
                          placeholder: "Type Categories",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            categories: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="categories"
                        className="block text-sm font-medium  text-gray-700 multipleselectlabelskill"
                      >
                        Categories
                      </label>
                    </div>
                  </div>
                </div>
                <div className="relative job-search-label-section singleselect2">
                  <Select2
                    id="skills"
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    name="skills"
                    defaultValue={formData?.skills}
                    multiple
                    onSelect={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        skills: Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        ),
                      }))
                    }
                    data={skills}
                    options={{ placeholder: "Type Skills" }}
                  />
                  <label
                    htmlFor="skills"
                    className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                  >
                    Skills
                  </label>
                </div>
                <div className="grid lg:grid-cols-2 lg:gap-x-5">
                  <div className="animated-form profileheadlinediv m-0">
                    <div className="relative ">
                      <Select2
                        className="coursetypesdropdown"
                        id="coursetype"
                        name="coursetype"
                        multiple
                        defaultValue={formData?.coursetype}
                        data={courseTypes}
                        options={{
                          placeholder: "Select Course Types",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            coursetype: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="coursetype"
                        className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                      >
                        Course Type
                      </label>
                    </div>
                  </div>
                  <div className="animated-form profileheadlinediv m-0">
                    <div className="relative ">
                      <Select2
                        className="courselevelsdropdown"
                        id="courselevel"
                        name="courselevel"
                        multiple
                        defaultValue={formData?.courseLevel}
                        data={courseLevels}
                        options={{
                          placeholder: "Select Course Levels",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            courseLevel: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="courselevel"
                        className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                      >
                        Course Level
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv m-0">
                    <div className="relative ">
                      <Select2
                        className="courselevelsdropdown"
                        id="courseDuration"
                        name="courseDuration"
                        multiple
                        defaultValue={formData?.courseDuration}
                        data={courseDurations}
                        options={{
                          placeholder: "Select Course Levels",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            courseDuration: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="courseDuration"
                        className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                      >
                        Course Duration
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv m-0">
                    <div className="relative ">
                      <Select2
                        className="languagesdropdown"
                        id="languages"
                        name="languages"
                        multiple
                        defaultValue={formData?.languages}
                        data={languages} // Ensure `languages` is an array of available language options
                        options={{
                          placeholder: "Type Languages",
                        }}
                        onSelect={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            languages: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          }))
                        }
                      />
                      <label
                        htmlFor="languages"
                        className="block text-sm font-medium  text-gray-700 multipleselectlabelskill"
                      >
                        Languages
                      </label>
                    </div>
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-5">
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        type="number"
                        name="price"
                        placeholder=""
                        value={formData.price}
                        onChange={handleChange}
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        id="price"
                      />
                      <label htmlFor="price" className="profileheadline">
                        Price
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        type="number"
                        name="discountPrice"
                        placeholder=""
                        value={formData.discountPrice}
                        onChange={handleChange}
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        id="discountPrice"
                      />
                      <label
                        htmlFor="discountPrice"
                        className="profileheadline"
                      >
                        Discount Price
                      </label>
                    </div>
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-5 mt-5">
                  <div>
                    <div
                      className=" flex m-auto hrp-upload-image"
                      onChange={(e) => handleUpload(e.target.files[0])}
                    >
                      <div
                        className=" flex justify-center w-full"
                        style={{ cursor: "pointer" }}
                      >
                        {formData.courseThumbnail ? (
                          <div className="hrp-upload-image-border">
                            {" "}
                            <img
                              className="uploadImage-logo"
                              src={
                                BASE_URL_PLATFORM +
                                "/uploads/" +
                                formData.courseThumbnail
                              }
                              alt="courseThumbnail"
                            />
                          </div>
                        ) : (
                          <div className="hrp-upload-image-border">
                            <img
                              src={DefaultImage}
                              className="DefaultImage-logo"
                              alt="Default"
                            />
                          </div>
                        )}
                      </div>
                      <label
                        htmlFor="courseThumbnail"
                        className=" hrp-upload-image-label"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="courseThumbnail"
                          name="courseThumbnail"
                          className="w-full   p-2 "
                        />
                      </label>
                    </div>
                    <div
                      className="flex justify-center py-3  font-semibold text-sm "
                      style={{ color: "#003366" }}
                    >
                      Upload Thumbnail
                    </div>
                  </div>
                  <div className="flex-1 form-group">
                    <div className="animated-form profileheadlinediv">
                      <div className="form-group">
                        <div className="form-group">
                          <input
                            type="text"
                            name="courseVideoPreview"
                            value={formData.courseVideoPreview}
                            onChange={handleVideoLinkChange}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            id="courseVideoPreview"
                            placeholder="Enter video link"
                          />
                          <label
                            htmlFor="courseVideoPreview"
                            className="profileheadline"
                          >
                            Course Video Preview
                          </label>
                        </div>
                      </div>

                      {/* Video Preview Section */}
                      {formData.courseVideoPreview && (
                        <div className="video-preview mt-4">
                          <iframe
                            src={formData.courseVideoPreview}
                            width="200"
                            height="200"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Video Preview"
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div
                    className="flex-1  form-group "
                    onChange={(e) => handleVideoUpload(e.target.files[0])}
                  >
                    <div
                      className="addpageupload categoryEditResult text"
                      style={{ cursor: "pointer" }}
                    >
                      {formData.courseVideoPreview ? (
                        <video
                          className="video-preview mt-2 w-70 h-30 rounded-lg"
                          style={{ width: "35px" }}
                          controls
                        >
                          <source
                            src={
                              BASE_URL_PLATFORM +
                              "/uploads/" +
                              formData.courseVideoPreview
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="animated-form profileheadlinediv">
                      <div className="form-group">
                        <input
                          type="text"
                          name="courseVideoPreview"
                          onChange={handleFileChange}
                          className="border rounded w-full py-2 px-3 text-gray-700"
                          id="courseVideoPreview"
                        />
                        <label
                          htmlFor="courseVideoPreview"
                          className="profileheadline"
                        >
                          Course Video Preview
                        </label>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        type="file"
                        name="courseVideoPreview"
                        // onChange={handleFileChange}
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        id="courseVideoPreview"
                      />
                      <label
                        htmlFor="courseVideoPreview"
                        className="profileheadline"
                      >
                        Course Video Preview
                      </label>
                    </div>
                  </div> */}
                </div>

                <div className="mt-5">
                  <button
                    className="hrp-find-course-btn px-8 py-2 rounded-full"
                    onClick={nextStep}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h2 className="text-2xl font-bold">CPD Qualification</h2>
                <div>
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        type="number"
                        name="enrollmentLimit"
                        placeholder=""
                        value={formData.enrollmentLimit}
                        onChange={handleChange}
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        id="enrollmentLimit"
                      />
                      <label
                        htmlFor="enrollmentLimit"
                        className="profileheadline"
                      >
                        Enrollment Limit
                      </label>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 lg:gap-5">
                    <div className="animated-form profileheadlinediv m-0">
                      <div className="form-group">
                        <input
                          type="date"
                          name="releaseDate"
                          placeholder=""
                          value={formData.releaseDate}
                          onChange={handleChange}
                          className="border rounded w-full py-2 px-3 text-gray-700"
                          id="releaseDate"
                        />
                        <label
                          htmlFor="releaseDate"
                          className="profileheadline"
                        >
                          Release Date
                        </label>
                      </div>
                    </div>
                    <div className="animated-form profileheadlinediv m-0">
                      <div className="relative ">
                        <Select2
                          className="coursetypesdropdown"
                          id="coursetype"
                          name="coursetype"
                          multiple
                          defaultValue={formData?.coursetype}
                          data={courseTypes}
                          options={{
                            placeholder: "Select Course Types",
                          }}
                          onSelect={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              coursetype: Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              ),
                            }))
                          }
                        />
                        <label
                          htmlFor="coursetype"
                          className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                        >
                          Course Type
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <ReactQuill
                        theme="snow"
                        name="courseObjectives"
                        modules={modules}
                        value={formData.courseObjectives || ""}
                        onChange={(e) =>
                          handleQuillChange(e, "courseObjectives")
                        }
                        className="border rounded w-full text-gray-700"
                        id="courseObjectives"
                      />
                      <label
                        htmlFor="courseObjectives"
                        className="profileheadline description-label"
                      >
                        Course Objectives
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv ">
                    <div className="form-group">
                      <ReactQuill
                        theme="snow"
                        name="prerequisites"
                        modules={modules}
                        value={formData.prerequisites || ""}
                        onChange={(e) => handleQuillChange(e, "prerequisites")}
                        className="border rounded w-full text-gray-700"
                        id="prerequisites"
                      />
                      <label
                        htmlFor="prerequisites"
                        className="profileheadline description-label"
                      >
                        Prerequisites
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv ">
                    <div className="form-group">
                      <ReactQuill
                        theme="snow"
                        name="targetAudience"
                        value={formData.targetAudience || ""}
                        onChange={(e) => handleQuillChange(e, "targetAudience")}
                        modules={modules}
                        className="border rounded w-full text-gray-700"
                        id="targetAudience"
                      />
                      <label
                        htmlFor="targetAudience"
                        className="profileheadline description-label"
                      >
                        Target Audience
                      </label>
                    </div>
                  </div>

                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <ReactQuill
                        theme="snow"
                        name="courseResources"
                        modules={modules}
                        value={formData?.courseResources.join("\n")}
                        onChange={(e) =>
                          handleQuillChange(e, "courseResources")
                        }
                        className="border rounded w-full text-gray-700"
                        id="courseResources"
                      />
                      <label
                        htmlFor="courseResources"
                        className="profileheadline description-label"
                      >
                        Course Resources
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between  gap-3 mt-5">
                  <button
                    className="joblist-cardbtnapply px-8 py-2"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="hrp-find-course-btn px-8 py-2 rounded-full"
                    onClick={nextStep}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="hrp-container ">
                <h2 className="text-2xl font-bold">Curriculum</h2>
                <div className="hrp-curriculum">
                  <form className="p-4 hrp-curriculum-form">
                    {sections.map((section, sectionIndex) => (
                      <div
                        key={sectionIndex}
                        className="mb-4 border-b pb-4 hrp-curriculum-Lecture-section"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <div className="animated-form profileheadlinediv w-full">
                            <div className="form-group">
                              <input
                                type="text"
                                name="title"
                                value={section.title}
                                onChange={(e) =>
                                  handleSectionChange(sectionIndex, e)
                                }
                                placeholder=""
                                className="border p-2 rounded w-full"
                              />
                              <label
                                htmlFor="seoTitle"
                                className="profileheadline"
                              >
                                {`Topic ${sectionIndex + 1} `}
                              </label>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveSection(sectionIndex)}
                            className="ml-4 text-white p-2 rounded"
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 34 34"
                              fill="#FF0000"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_69_18)">
                                <path
                                  d="M28.3333 7.08301C28.7091 7.08301 29.0694 7.23226 29.3351 7.49794C29.6007 7.76362 29.75 8.12395 29.75 8.49967C29.75 8.8754 29.6007 9.23573 29.3351 9.50141C29.0694 9.76709 28.7091 9.91634 28.3333 9.91634H26.9167L26.9124 10.0169L25.5907 28.5342C25.5398 29.249 25.2199 29.918 24.6955 30.4064C24.1711 30.8948 23.4811 31.1664 22.7644 31.1663H11.2342C10.5175 31.1664 9.82751 30.8948 9.30309 30.4064C8.77866 29.918 8.4588 29.249 8.40792 28.5342L7.08617 10.0183L7.08333 9.91634H5.66667C5.29094 9.91634 4.93061 9.76709 4.66493 9.50141C4.39926 9.23573 4.25 8.8754 4.25 8.49967C4.25 8.12395 4.39926 7.76362 4.66493 7.49794C4.93061 7.23226 5.29094 7.08301 5.66667 7.08301H28.3333ZM24.0791 9.91634H9.92092L11.2356 28.333H22.7644L24.0791 9.91634ZM19.8333 2.83301C20.2091 2.83301 20.5694 2.98226 20.8351 3.24794C21.1007 3.51362 21.25 3.87395 21.25 4.24967C21.25 4.6254 21.1007 4.98573 20.8351 5.25141C20.5694 5.51709 20.2091 5.66634 19.8333 5.66634H14.1667C13.7909 5.66634 13.4306 5.51709 13.1649 5.25141C12.8993 4.98573 12.75 4.6254 12.75 4.24967C12.75 3.87395 12.8993 3.51362 13.1649 3.24794C13.4306 2.98226 13.7909 2.83301 14.1667 2.83301H19.8333Z"
                                  className="icon-path"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_69_18">
                                  <rect
                                    width="34"
                                    height="34"
                                    fill="white"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                        <div className="lg:pl-4">
                          {section.lectures.map((lecture, lectureIndex) => (
                            <div
                              key={lectureIndex}
                              className="my-4 hrp-curriculum-Lecture-card"
                            >
                              <div className="lg:flex justify-between items-center mb-2">
                                <div className="animated-form profileheadlinediv w-full">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="title"
                                      value={lecture.title}
                                      onChange={(e) =>
                                        handleLectureChange(
                                          sectionIndex,
                                          lectureIndex,
                                          e
                                        )
                                      }
                                      placeholder=""
                                      className="border p-2 rounded w-full mr-2"
                                    />
                                    <label
                                      htmlFor="seoTitle"
                                      className="profileheadline"
                                    >
                                      {`Sub Topic ${lectureIndex + 1} `}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-between items-center mb-2">
                                <div className="lg:flex gap-3 w-full">
                                  <div className="animated-form profileheadlinediv lg:w-3/4">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        name="videoLink"
                                        value={lecture.videoLink}
                                        onChange={(e) =>
                                          handleLectureChange(
                                            sectionIndex,
                                            lectureIndex,
                                            e
                                          )
                                        }
                                        placeholder=""
                                        className="border p-2 rounded w-full mr-2"
                                      />
                                      <label
                                        htmlFor="seoTitle"
                                        className="profileheadline"
                                      >
                                        Video Link
                                      </label>
                                    </div>
                                  </div>
                                  <div className="animated-form profileheadlinediv ">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        name="duration"
                                        value={lecture.duration}
                                        onChange={(e) =>
                                          handleLectureChange(
                                            sectionIndex,
                                            lectureIndex,
                                            e
                                          )
                                        }
                                        placeholder=""
                                        className="border p-2 rounded w-32"
                                      />
                                      <label
                                        htmlFor="seoTitle"
                                        className="profileheadline"
                                      >
                                        Duration
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveLecture(
                                      sectionIndex,
                                      lectureIndex
                                    )
                                  }
                                  className=" text-white p-2 rounded mt-2"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-x-circle "
                                    color="red"
                                  >
                                    <g>
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="9"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="15"
                                        y2="15"
                                      ></line>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => handleAddLecture(sectionIndex)}
                            className="text-white p-2 px-3 rounded-full ViewDetails-btn"
                          >
                            Add Sub Topic
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddSection}
                      className="text-white p-2 px-3 rounded-full ViewDetails-btn mt-4"
                    >
                      Add Topic
                    </button>
                  </form>
                </div>

                <div className="flex justify-between  gap-3 mt-5">
                  <button
                    className="joblist-cardbtnapply px-8 py-2"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="hrp-find-course-btn px-8 py-2 rounded-full"
                    onClick={nextStep}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
            {step === 4 && (
              <div>
                <h2 className="text-2xl font-bold">Instructor Information</h2>

                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <input
                      type="text"
                      name="instructorName"
                      placeholder=""
                      value={formData.instructorName}
                      onChange={(e) => {
                        handleChange(e);
                        // Set error if the field is empty
                      }}
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="instructorName"
                    />
                    <label htmlFor="instructorName" className="profileheadline">
                      Instructor Name
                    </label>
                  </div>
                  {!formData.instructorName && (
                    <p className="text-red-500 text-sm">
                      Instructor Name is required
                    </p>
                  )}
                </div>

                <div className="animated-form profileheadlinediv ">
                  <div className="form-group">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      name="instructorBio"
                      value={formData.instructorBio || ""}
                      onChange={(e) => handleQuillChange(e, "instructorBio")}
                      className="border rounded w-full text-gray-700"
                      id="instructorBio"
                    />
                    <label
                      htmlFor="instructorBio"
                      className="profileheadline description-label"
                    >
                      Instructor Bio
                    </label>
                  </div>
                </div>

                <div className="flex justify-between  gap-3 mt-5">
                  <button
                    className="joblist-cardbtnapply px-8 py-2"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="hrp-find-course-btn px-8 py-2 rounded-full"
                    onClick={nextStep}
                    disabled={!formData.instructorName || instructorError}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
            {step === 5 && (
              <>
                <div>
                  <div>
                    <div className="mt-5">
                      <div>
                        <input
                          type="checkbox"
                          name="certification"
                          checked={formData.certification || false} // Ensure it defaults to false
                          onChange={handleChange}
                          className="border rounded"
                          id="certification"
                        />
                        <label htmlFor="certification" className="mx-2">
                          Certification
                        </label>
                      </div>

                      {/* Section for Workshop, shown only when certification is checked */}
                      {formData.certification && (
                        <>
                          <div className="jobtypeinput">
                            <div className="jobtypeinput-btn mt-2">
                              {["Attendance Certificate", "workshop"].map(
                                (type) => (
                                  <label
                                    key={type}
                                    htmlFor={type}
                                    className={`jobtype-label ${
                                      formData.jobType.includes(type)
                                        ? "activejobtype"
                                        : "notactivejobtype"
                                    }`}
                                    onClick={() =>
                                      handleCheckboxChange("jobType", type)
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      name="jobType"
                                      id={type}
                                      value={type}
                                      checked={formData.jobType.includes(type)}
                                      readOnly
                                      className="hidden-checkbox"
                                      onClick={() =>
                                        handleCheckboxChange("jobType", type)
                                      }
                                    />
                                    {type}
                                  </label>
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <div className="">
                    {toolswithicon?.InformationTechnology?.map(
                      (toolsicon, index) => (
                        <div key={index} className="flex items-center mb-4">
                          <div
                            dangerouslySetInnerHTML={{ __html: toolsicon.icon }}
                            className="w-16 h-16 mr-4"
                          />
                          <span>{toolsicon.name}</span>
                        </div>
                      )
                    )}
                    {toolswithicon?.healthcareTools?.map((toolsicon, index) => (
                      <div key={index} className="flex items-center mb-4">
                        <div
                          dangerouslySetInnerHTML={{ __html: toolsicon.icon }}
                          className="w-16 h-16 mr-4"
                        />
                        <span>{toolsicon.name}</span>
                      </div>
                    ))}
                  </div> */}

                  <div className="flex justify-between  gap-3 mt-5">
                    <button
                      className="joblist-cardbtnapply px-8 py-2"
                      onClick={prevStep}
                    >
                      Back
                    </button>
                    <button
                      className="hrp-find-course-btn px-8 py-2 rounded-full"
                      onClick={nextStep}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </>
            )}
            {step === 6 && (
              <div>
                <h2 className="text-2xl font-bold"> SEO</h2>
                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <input
                      type="text"
                      name="seoTitle"
                      placeholder=""
                      value={formData.seoTitle}
                      onChange={handleChange}
                      className="border rounded w-full py-2 px-3 text-gray-700"
                      id="seoTitle"
                    />
                    <label htmlFor="seoTitle" className="profileheadline">
                      SEO Title
                    </label>
                  </div>
                </div>
                <div className="animated-form profileheadlinediv ">
                  <div className="form-group">
                    <ReactQuill
                      theme="snow"
                      name="supportInformation"
                      modules={modules}
                      value={formData.supportInformation || ""}
                      onChange={(e) =>
                        handleQuillChange(e, "supportInformation")
                      }
                      className="border rounded w-full text-gray-700"
                      id="supportInformation"
                    />
                    <label
                      htmlFor="supportInformation"
                      className="profileheadline description-label"
                    >
                      Support Information
                    </label>
                  </div>
                </div>

                <div className="animated-form profileheadlinediv">
                  <div className="form-group">
                    <ReactQuill
                      theme="snow"
                      name="seoDescription"
                      modules={modules}
                      value={formData.seoDescription || ""}
                      onChange={(e) => handleQuillChange(e, "seoDescription")}
                      className="border rounded w-full text-gray-700"
                      id="seoDescription"
                    />
                    <label
                      htmlFor="seoDescription"
                      className="profileheadline description-label"
                    >
                      SEO Description
                    </label>
                  </div>
                </div>
                <div className="flex justify-between  gap-3 mt-5">
                  <button
                    className="joblist-cardbtnapply px-8 py-2"
                    onClick={prevStep}
                  >
                    Back
                  </button>
                  <button
                    className="hrp-find-course-btn px-8 py-2 rounded-full"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>

        <div className="w-2/6 border-r pr-4 hrp-course-information flex justify-center">
          <ul>
            <li
              className={`cursor-pointer ${step === 1 ? "active  " : ""}`}
              onClick={() => setStep(1)}
            >
              <div className="flex  gap-3">
                <div className="">
                  <div className=" hrp-seller-course-icon ">
                    {" "}
                    <FeatherIcon
                      icon="bookmark"
                      size={34}
                      className="m-auto hover:fill-[#ffa500] " // Hover color
                    />
                  </div>
                  <div className="hrp-process-height"></div>
                </div>
                <div className="processstep-tittle">Course Information</div>
              </div>
            </li>
            <li
              className={`cursor-pointer ${step === 2 ? "active" : ""}`}
              onClick={() => setStep(2)}
            >
              <div className="flex  gap-3">
                <div>
                  <div className=" hrp-seller-course-icon ">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="m-auto"
                    >
                      <path
                        d="M9 21H15V19H9V21ZM12 2C8.69 2 6 4.69 6 8C6 10.38 7.19 12.47 9 13.57V17H15V13.57C16.81 12.47 18 10.38 18 8C18 4.69 15.31 2 12 2ZM12 15H10V12.62L8.93 11.78C7.83 11.04 7 9.59 7 8C7 5.79 8.79 4 11 4C13.21 4 15 5.79 15 8C15 9.59 14.17 11.04 13.07 11.78L12 12.62V15Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="hrp-process-height"></div>
                </div>
                <div className="processstep-tittle">CPD Qualification</div>
              </div>
            </li>

            <li
              className={`cursor-pointer ${step === 3 ? "active" : ""}`}
              onClick={() => setStep(3)}
            >
              <div className="flex  gap-3">
                <div>
                  <div className=" hrp-seller-course-icon ">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="m-auto"
                    >
                      <path
                        d="M6 2H16C17.1 2 18 2.9 18 4V18C18 19.1 17.1 20 16 20H6C4.9 20 4 19.1 4 18V4C4 2.9 4.9 2 6 2ZM6 18H16V4H6V18ZM7 8H15V6H7V8ZM7 12H15V10H7V12ZM7 16H15V14H7V16Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="hrp-process-height"></div>
                </div>
                <div className="processstep-tittle">Curriculum</div>
              </div>
            </li>
            <li
              className={`cursor-pointer ${step === 4 ? "active" : ""}`}
              onClick={() => setStep(4)}
            >
              <div className="flex  gap-3">
                <div>
                  <div className=" hrp-seller-course-icon ">
                    <FeatherIcon
                      icon="user"
                      size={34}
                      className="m-auto hover:fill-[#ffa500] "
                    />
                  </div>
                  <div className="hrp-process-height"></div>
                </div>
                <div className="processstep-tittle">Instructor Information</div>
              </div>
            </li>
            <li
              className={`cursor-pointer ${step === 5 ? "active" : ""}`}
              onClick={() => setStep(5)}
            >
              <div className="flex  gap-3">
                <div>
                  <div className=" hrp-seller-course-icon ">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="m-auto"
                    >
                      <path
                        d="M12 2C7.59 2 4 5.59 4 10C4 14.41 7.59 18 12 18C16.41 18 20 14.41 20 10C20 5.59 16.41 2 12 2ZM12 16C8.69 16 6 13.31 6 10C6 6.69 8.69 4 12 4C15.31 4 18 6.69 18 10C18 13.31 15.31 16 12 16ZM10 11L8.5 9.5L7.09 10.91L10 13.83L17 6.83L15.59 5.41L10 11ZM7 22L9 20L11 22L13 20L15 22L15 19.5L11 17L7 19.5V22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="hrp-process-height"></div>
                </div>
                <div className="processstep-tittle">
                  Ofqual Accredited Qualification
                </div>
              </div>
            </li>
            <li
              className={`cursor-pointer ${step === 6 ? "active" : ""}`}
              onClick={() => setStep(6)}
            >
              <div className="flex  gap-3">
                <div className=" hrp-seller-course-icon ">
                  <FeatherIcon
                    icon="search"
                    size={34}
                    className="m-auto hover:fill-[#ffa500] "
                  />
                </div>
                <div className="processstep-tittle">SEO</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default MultiStepCourseForm;
