import React, { useState, useEffect } from "react";
import Select2 from "../../Common/Select2Wrapper";
import {
  qualifications,
  FIELD_OF_STUDY_OPTIONS,
} from "../../../helpers/Object";

const AddEducation = ({ onClose, onSave, initialValues, category, index }) => {
  const [formData, setFormData] = useState({
    institution: "",
    degree: "",
    stream: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  useEffect(() => {
    if (initialValues) {
      // Set formData with initial values from the prop
      setFormData({
        institution: initialValues.institution || "",
        degree: initialValues.degree || "",
        stream: initialValues.stream || "",
        startDate: initialValues.startDate || "",
        endDate: initialValues.endDate || "",
        description: initialValues.description || "",
      });
    }
  }, [initialValues]);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setFormData({ ...formData, degree: selectedValue });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose("");
    // Handle form submission logic here
  };

  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                {initialValues != "" ? "Edit Education " : "Add Education"}
              </h1>
              <form
                onSubmit={handleSubmit}
                className="register-form-section animated-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="institution"
                    placeholder=""
                    value={formData.institution}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                    required
                  />
                  <label htmlFor="institution" className="form-input-label">
                    Institution Name
                  </label>
                </div>
                <div className="grid lg:grid-cols-2 lg:gap-x-5">
                  <div className="animated-form mt-3">
                    <div
                      className="relative job-search-label-section singleselect2 statusenroll"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <Select2
                        data={qualifications.map((degree) => ({
                          id: degree,
                          text: degree,
                        }))}
                        className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                        defaultValue={formData.degree}
                        onChange={(e) => handleSelectChange(e)} //
                        options={{
                          placeholder: "Select Status",
                          width: "100%",
                          theme: "classic",
                        }}
                      />
                      <label
                        htmlFor="degree"
                        className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                      >
                        Degree
                      </label>
                    </div>
                  </div>
                  <div className="animated-form mt-3">
                    <div
                      className="relative job-search-label-section singleselect2 statusenroll"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <Select2
                        data={FIELD_OF_STUDY_OPTIONS.map((field) => ({
                          id: field,
                          text: field,
                        }))}
                        name="stream"
                        className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                        defaultValue={formData.stream}
                        onChange={(e) =>
                          setFormData({ ...formData, stream: e.target.value })
                        } //
                        options={{
                          placeholder: "Select Status",
                          width: "100%",
                          theme: "classic",
                        }}
                      />
                      <label
                        htmlFor="stream"
                        className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                      >
                        Field of Study
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-2 grid grid-cols-2 gap-x-5 mt-5">
                  <div className="form-group">
                    <input
                      type="date"
                      id="startDate"
                      placeholder=""
                      value={formData.startDate}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                      required
                    />
                    <label htmlFor="startDate" className="form-input-label">
                      Start Date
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="date"
                      id="endDate"
                      placeholder=""
                      value={formData.endDate}
                      onChange={handleChange}
                      className="shadow w-full border rounded py-2 px-3"
                    />
                    <label htmlFor="endDate" className="form-input-label">
                      End Date
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    id="description"
                    placeholder=""
                    value={formData.description}
                    onChange={handleChange}
                    className="shadow w-full border rounded py-2 px-3"
                  />
                  <label htmlFor="description" className="form-input-label">
                    Description
                  </label>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="hrp-find-course-btn py-3 px-10 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};
export default AddEducation;
